import { FilterOrder, FiltersObject } from "../models/FilterObj";

export const columns = [
  "TERMÉK",
  "",
  "EGYSÉGÁR",
  "ÁFA %",
  "KEDVEZMÉNY",
  "NETTÓ",
  "ÁFA",
  "BRUTTÓ",
];

export const defaultNotDeletedFilter = {
  FieldName: "Deleted",
  FilterValue: 0,
  OperatorType: 0,
  FieldType: 0,
};

export const defaultFilterObj = {
  Skip: 0,
  Take: 10,
  Orders: [
    {
      FieldName: "Name",
      OrderWay: 0,
      OrderType: 0,
    } as FilterOrder,
  ],
  Filters: [[defaultNotDeletedFilter]],
} as FiltersObject;

export const DayPickerStrings = {
  months: [
    "Január",
    "Február",
    "Március",
    "Április",
    "Május",
    "Június",
    "Július",
    "Augusztus",
    "Szeptember",
    "Október",
    "November",
    "December",
  ],

  shortMonths: [
    "Jan",
    "Feb",
    "Márc",
    "Ápr",
    "Máj",
    "Jún",
    "Júl",
    "Aug",
    "Szep",
    "Okt",
    "Nov",
    "Dec",
  ],

  days: [
    "Vasárnap",
    "Hétfő",
    "Kedd",
    "Szerda",
    "Csütörtök",
    "Péntek",
    "Szombat",
  ],

  shortDays: ["V", "H", "K", "SZ", "CS", "P", "SZ"],

  goToToday: "Ugrás a mai napra",
  prevMonthAriaLabel: "Előző hónap",
  nextMonthAriaLabel: "Következő hónap",
  prevYearAriaLabel: "Előző év",
  nextYearAriaLabel: "Következő év",
};

export const quoteNames = [
  { Id: 0, Name: "Ajánlat" },
  { Id: 1, Name: "Szállítólevél" },
  { Id: 2, Name: "Megrendelés" },
  { Id: 3, Name: "Díjbekérő" },
  { Id: 4, Name: "Bizonylat" },
  { Id: 5, Name: "Payment Request" },
  { Id: 6, Name: "Quotation" },
  { Id: 7, Name: "Purchase Order" },
];

export const defaultInvoicingTypes = [
  { Id: "PAPER", Name: "Papír alapú számla" },
  { Id: "ELECTRONIC", Name: "Elektronikus számla" },
  { Id: "BOTH", Name: "Mindkettő" },
];
