import { makeStyles, shorthands } from "@fluentui/react-components";
import { COLOR } from "../utils/Enums";

export const useStylesVoucherDetail = makeStyles({
  head: {
    backgroundColor: COLOR.BIZ_PURPLE,

    ...shorthands.padding("0", "5px"),
  },
  headerCell: {
    color: "white",
    textTransform: "uppercase",
  },
  col: {
    ...shorthands.padding("5px"),
    display: "inline-flex",
    alignItems: "center",
    flexShrink: 1,
  },
  body: {},
  cell: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    fontSize: "12px",
    lineHeight: "1.42857143",
  },
  highlightedRow: {
    backgroundColor: COLOR.BIZ_DARK_ORANGE,
    ":hover": {
      backgroundColor: COLOR.BIZ_DARK_ORANGE,
    },
  },
  normalRow: {
    backgroundColor: "transparent",
    ":hover .icon-container": {
      opacity: 1,
    },
  },
});
