import { makeStyles, shorthands } from "@fluentui/react-components";

export const usePageHeaderStyle = makeStyles({
  container: {
    width: "100%",
    display: "inline-flex",
    marginTop: "0.67em",
    marginBottom: "0.67em",
  },
  title: {
    fontSize: "27px",
    display: "flex",
    alignItems: "center",
    fontWeight: "normal",
    marginLeft: "10px",
  },
});
