import { makeStyles, shorthands } from "@fluentui/react-components";
import { COLOR } from "../utils/Enums";

export const useStylesInvoiceForm = makeStyles({
  floatRight: { whiteSpace: "nowrap" },
  alignRight: {
    textAlign: "right",
    justifyContent: "flex-end",
    ...shorthands.margin("5px"),
  },
  vouchernumber: {
    height: "20px",
    fontWeight: 700,
    ...shorthands.margin("0px"),
    marginBottom: "10px",
  },
  label: { width: "100px", display: "inline-block", marginRight: "5px" },
  button: { ...shorthands.margin("2px") },
  client: {
    ...shorthands.padding("15px", "15px", "0px"),
    display: "inline-flex",
  },

  comments: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "25px",
    height: "25px",
    ...shorthands.border("2px", "solid", "#000000"),
    backgroundColor: "transparent",
    position: "relative",
    WebkitBorderRadius: "50%",
    MozBorderRadius: "50%",
    ...shorthands.borderRadius("50%"),
    ":hover": {
      fill: "white",
      backgroundColor: COLOR.BIZ_DARK_ORANGE,
      ...shorthands.border("2px", "solid", COLOR.BIZ_DARK_ORANGE),
    },
  },
  textarea: {
    width: "100%",
    height: "270px",
    ...shorthands.overflow("hidden"),
  },
  clientInfo: {
    marginTop: "-5px",
    whiteSpace: "normal", // This will allow the text to wrap to multiple lines
    textOverflow: "ellipsis",
    maxWidth: "70%",
    ...shorthands.overflow("hidden"),
  },
});
