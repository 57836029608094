import { MobileTabsProps } from "../../interfaces/MobileTabsProps";
import { TAB_TYPE } from "../../utils/Enums";

const MobileTabs: React.FC<MobileTabsProps> = ({
  activeTab,
  setActiveTab,
  needPartnerTab,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "inline-flex",
        marginBottom: "5px",
      }}
    >
      {needPartnerTab && (
        <div
          style={{
            borderTop: "3px solid #3f76bb",
            paddingTop: "5px",
            paddingBottom: "5px",
            width: needPartnerTab ? "32%" : "50%",
            textAlign: "center",
            background: activeTab === TAB_TYPE.CUSTOMERS ? "#3f76bb" : "",
            color: activeTab === TAB_TYPE.CUSTOMERS ? "white" : "",
          }}
          onClick={() => setActiveTab(TAB_TYPE.CUSTOMERS)}
        >
          1. PARTNEREK
        </div>
      )}
      <div
        style={{
          borderTop: needPartnerTab ? "" : "3px solid #3f76bb",
          borderBottom: needPartnerTab ? "3px solid #3f76bb" : "",
          borderRight: needPartnerTab ? "3px solid #3f76bb" : "",
          borderLeft: needPartnerTab ? "3px solid #3f76bb" : "",
          paddingTop: needPartnerTab ? "8px" : "5px",
          paddingBottom: "5px",
          width: needPartnerTab ? "32%" : "50%",
          textAlign: "center",
          background: activeTab === TAB_TYPE.PRODUCTS ? "#3f76bb" : "",
          color: activeTab === TAB_TYPE.PRODUCTS ? "white" : "",
        }}
        onClick={() => setActiveTab(TAB_TYPE.PRODUCTS)}
      >
        {needPartnerTab ? "2. TERMÉKEK" : "1. TERMÉKEK"}
      </div>
      <div
        style={{
          borderTop: "3px solid #3f76bb",
          paddingTop: "5px",
          paddingBottom: "5px",
          width: needPartnerTab ? "32%" : "50%",
          textAlign: "center",
          background: activeTab === TAB_TYPE.INVOICE ? "#3f76bb" : "",
          color: activeTab === TAB_TYPE.INVOICE ? "white" : "",
        }}
        onClick={() => setActiveTab(TAB_TYPE.INVOICE)}
      >
        {needPartnerTab ? "3. BIZONYLAT" : "2. SZÁMLA"}
      </div>
    </div>
  );
};

export default MobileTabs;
