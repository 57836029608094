import { Fragment, useContext, useEffect } from "react";
import useWindowDimensions from "./WindowDimensions";
import { DeviceContext } from "../GlobalContexts";

type Props = {
  children: React.ReactNode;
};

export const isMobileCheck = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      isMobileCheck.Android() ||
      isMobileCheck.BlackBerry() ||
      isMobileCheck.iOS() ||
      isMobileCheck.Opera() ||
      isMobileCheck.Windows()
    );
  },
};

const DeviceCheck = ({ children }: Props) => {
  const { setIsMobile } = useContext(DeviceContext);
  const { height, width } = useWindowDimensions();

  const changeDeviceParams = () => {
    setIsMobile(width <= 1000);
  };

  useEffect(() => {
    changeDeviceParams();
  }, [height, width]);

  return <Fragment>{children}</Fragment>;
};

export default DeviceCheck;
