export enum INTERNAL_ROUTE {
  START = "/",
}

export enum LOCAL_STORAGE_KEY {
  MS_AUTH_TOKEN = "msAuthToken",
}

export enum COLOR {
  BIZ_LIGHT_BLUE = "rgba(221, 232, 241, 0.75)",
  BIZ_BLUE = "#d61719",
  BIZ_BLACK = "#000000",
  BIZ_PURPLE = "#6d2243",
  BIZ_GREEN = "#1f8f34",
  BIZ_GREY = "#666666",
  BIZ_LIGHT_GREY = "#dbe5ec",
  BIZ_DARK_GREY = "#939393",
  BIZ_LIGHTER_GREY = "rgba(207, 220, 237, 0.66)",
  BIZ_LIGHT_GREEN = "#85871a",
  BIZ_DARK_GREEN = "#2e7d32",
  BIZ_ORANGE = "#f78f1e",
  BIZ_DARK_ORANGE = "#3f76bb",
  BIZ_RED = "#ba2640",
}

export enum TAB_TYPE {
  CUSTOMERS,
  PRODUCTS,
  INVOICE,
}

export enum LIST_TYPE {
  CUSTOMER,
  PRODUCT,
}

export enum CUSTOMER_MODAL_TAB {
  DATA,
  CRM,
  PAYMENT,
  LOCAL,
}

export enum INPUT_VALIDATION {
  IBAN = 1,
  BANK_ACCOUNT = 2,
  TAX_NUMBER_GROUP_ID = 3,
}
