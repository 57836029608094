/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoiceResultDto } from '../models/InvoiceResultDto';
import type { ModifierInvoiceDto } from '../models/ModifierInvoiceDto';
import type { PreviewResultDto } from '../models/PreviewResultDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ModifierInvoiceService {

    /**
     * @param id 
     * @returns ModifierInvoiceDto Success
     * @throws ApiError
     */
    public static getModifierInvoice(
id: number,
): CancelablePromise<ModifierInvoiceDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/modifier/invoice/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InvoiceResultDto Success
     * @throws ApiError
     */
    public static postModifierModify(
requestBody?: ModifierInvoiceDto,
): CancelablePromise<InvoiceResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/modifier/modify',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns InvoiceResultDto Success
     * @throws ApiError
     */
    public static getModifierStornomodifier(
id: number,
): CancelablePromise<InvoiceResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/modifier/stornomodifier/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns PreviewResultDto Success
     * @throws ApiError
     */
    public static postModifierPreview(
requestBody?: ModifierInvoiceDto,
): CancelablePromise<PreviewResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/modifier/preview',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
