import ReactCardFlip from "react-card-flip";
import { CardFlipProps } from "../../interfaces/CardFlipProps";

const CardFlip: React.FC<CardFlipProps> = ({ isFlipped, children }) => {
  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      {children as any}
    </ReactCardFlip>
  );
};

export default CardFlip;
