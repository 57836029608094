import { makeStyles, shorthands } from "@fluentui/react-components";
import { COLOR } from "../utils/Enums";

export const useStylesIcon = makeStyles({
  container: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "28px",
    height: "28px",
    ...shorthands.border("2px", "solid", "#000000"),
    backgroundColor: "transparent",
    WebkitBorderRadius: "50%",
    MozBorderRadius: "50%",
    ...shorthands.borderRadius("50%"),
    marginLeft: "10px",
  },
});
