import { Input, Label, Tooltip, useId } from "@fluentui/react-components";
import { InputProps } from "../../interfaces/InputWithLabelProps";
import { INPUT_VALIDATION } from "../../utils/Enums";
import CompanySearcharbleSelect from "./CompanySearchableSelect";
import { useState } from "react";

const InputComponent: React.FC<InputProps> = ({
  label,
  value,
  name,
  onChange,
  disabled,
  fullwidth,
  autowidth,
  placeholder,
  minwidth,
  small,
  onBlur,
  numeric,
  transparent,
  onFocusLeave,
  validation,
  maxLength,
  showTooltip,
  id,
  tooltip,
  tooltipColor,
  iconCount,
  icon,
  min,
  max,
  contentBefore,
  contentAfter,
  withCompanySearch,
  setCustomerObj,
}) => {
  const smallId = useId("input-small");
  const containerId = `${id}-span`;
  const [isFocused, setIsFocused] = useState(false);

  function format(e: any) {
    if (
      validation &&
      validation === INPUT_VALIDATION.BANK_ACCOUNT &&
      maxLength &&
      e.target.value.length < maxLength &&
      e.target.value.length > 1
    ) {
      var val = e.target.value.split("-").join("");
      if (val.length > 16) {
        e.target.value =
          val.slice(0, 8) +
          "-" +
          val.slice(8, 16) +
          "-" +
          val.slice(16, val.length);
      } else if (val.length > 8) {
        e.target.value = val.slice(0, 8) + "-" + val.slice(8, val.length);
      } else if (val.length > 7) {
        e.target.value = val.slice(0, 8);
      }
      return true;
    } else if (
      validation &&
      validation === INPUT_VALIDATION.IBAN &&
      maxLength &&
      e.target.value.length < maxLength &&
      e.target.value.length > 1
    ) {
      var val = e.target.value.split(" ").join("");
      let list = val.match(/.{1,4}/g);
      e.target.value = list.join(" ");
      return true;
    } else if (
      validation &&
      validation === INPUT_VALIDATION.TAX_NUMBER_GROUP_ID &&
      maxLength &&
      e.target.value.length < maxLength &&
      e.target.value.length > 1
    ) {
      var val = e.target.value.split("-").join("");
      if (val.length > 9) {
        e.target.value =
          val.slice(0, 8) +
          "-" +
          val.slice(8, 9) +
          "-" +
          val.slice(9, val.length);
      } else if (val.length > 8) {
        e.target.value = val.slice(0, 8) + "-" + val.slice(8, val.length);
      } else if (val.length > 7) {
        e.target.value = val.slice(0, 8);
      }
      return true;
    } else {
      return false;
    }
  }

  return (
    <span id={containerId}>
      {label && (
        <Label
          size="small"
          style={{
            overflowWrap: "break-word",
            wordBreak: "break-all",
            marginRight: "5px",
          }}
          htmlFor={smallId}
        >
          {label}
        </Label>
      )}
      <Tooltip
        content={{
          children: tooltip,
          style: { backgroundColor: tooltipColor ?? "#b33c39", color: "white" },
        }}
        relationship="description"
        visible={showTooltip ?? false}
        withArrow
      >
        <span>
          <Input
            contentBefore={contentBefore}
            contentAfter={contentAfter}
            style={{
              backgroundColor:
                disabled || transparent ? "transparent" : "white",
              border: transparent ? "none" : "auto",
              width:
                iconCount === 1
                  ? "80%"
                  : iconCount === 2
                  ? "70%"
                  : fullwidth
                  ? "100%"
                  : autowidth
                  ? "auto"
                  : "100px",
            }}
            appearance="outline"
            size={small ? "small" : "medium"}
            id={id ?? smallId}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            onFocusCapture={() => {
              if (!isFocused) setIsFocused(true);
            }}
            onBlur={() => {
              if (isFocused) setIsFocused(false);
              onBlur as any;
            }}
            type={numeric ? "number" : "text"}
            onBlurCapture={onFocusLeave}
            onInput={format}
            onKeyPress={format}
            maxLength={maxLength}
            max={max}
            min={min}
          />
          {withCompanySearch && (
            <CompanySearcharbleSelect
              parentId={containerId ?? ""}
              value={value}
              setCustomerObj={setCustomerObj}
              isFocusedInput={isFocused}
            />
          )}
        </span>
      </Tooltip>
      {icon}
    </span>
  );
};

export default InputComponent;
