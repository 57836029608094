import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
} from "@fluentui/react-components";
import DialogActionsComponent from "./DialogActionsComponent";
import { DialogSkeletonProps } from "../../interfaces/DialogSkeletonProps";
import { useContext } from "react";
import { DeviceContext } from "../../utils/GlobalContexts";

const DialogSkeleton: React.FC<DialogSkeletonProps> = ({
  open,
  setOpen,
  title,
  closeBtnText,
  saveBtnText,
  saveAction,
  children,
  isSaveLoading,
}) => {
  const { isMobile } = useContext(DeviceContext);
  return (
    <Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>
      <DialogSurface
        style={
          isMobile &&
          (title === "Partner szerkesztése" ||
            title === "Új partner" ||
            title === "Bizonylat küldése e-mailben" ||
            title === "Tétel")
            ? { width: "100%", height: "100%" }
            : isMobile
            ? { width: "300px" }
            : title === "Partner szerkesztése" || title === "Új partner"
            ? { maxWidth: "1017px" }
            : title === "Bizonylat küldése e-mailben"
            ? { maxWidth: "700px" }
            : {}
        }
      >
        <DialogBody>
          <DialogTitle
            style={{
              backgroundColor: "#3f76bb",
              borderRadius: "10px 10px 0 0",
              margin: "-26px",
              marginBottom: 0,
              color: "white",
              paddingTop: "10px",
              paddingLeft: "20px",
            }}
          >
            <div
              style={{
                marginBottom: "15px",
              }}
            >
              {title}
            </div>
          </DialogTitle>
          <DialogContent style={{ marginTop: "5px" }}>{children}</DialogContent>
          <DialogActionsComponent
            closeBtnText={closeBtnText}
            saveBtnText={saveBtnText ?? "OK"}
            saveAction={saveAction}
            isSaveLoading={isSaveLoading}
          />
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default DialogSkeleton;
