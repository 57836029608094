import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LOCAL_STORAGE_KEY } from "../utils/Enums";

export default function FirstPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    // https://helyesbitoszamla.dev.adatpont.info/auth?dbGuid=509e8f43072b4bdca8201e1c2463921e&voucherType=quote&action=edit&id=11444
    getContext().then(() => {
      const voucherType =
        searchParams.get("voucherType") ??
        localStorage.getItem("voucherType") ??
        localStorage.getItem("vouchertype");

      if (voucherType === "quote") navigate("/quote");
      else if (voucherType === "modifier") navigate("/");
    });
  }, []);

  const getContext = async () => {
    const dbGuid = searchParams.get("dbGuid") ?? searchParams.get("dbguid");

    if (dbGuid) localStorage.setItem("dbGuid", dbGuid);

    const id = searchParams.get("id");
    if (id) localStorage.setItem("id", id);

    const action = searchParams.get("action");
    if (action) localStorage.setItem("action", action);

    const voucherType =
      searchParams.get("vouchertype") ?? searchParams.get("voucherType");
    if (voucherType) localStorage.setItem("voucherType", voucherType);
  };

  return <div></div>;
}
