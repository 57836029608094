import * as React from "react";
import DialogSkeleton from "./DialogSkeleton";
import { DialogProps } from "../../interfaces/DialogConfirmProps";
import InputComponent from "../input/InputComponent";
import { useState } from "react";

const DialogSetCurrency: React.FC<DialogProps> = ({
  title,
  message,
  open,
  setOpen,
  handleAction,
  okBtnText,
  closeBtnText,
  currencyRate,
  setCurrencyRate,
}) => {
  const [currencyValue, setCurrencyValue] = useState(1);

  const handleSaveCurrencyRate = () => {
    setCurrencyRate && setCurrencyRate(currencyValue);
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) setCurrencyValue(currencyRate ?? 1);
  }, [open]);

  const onChangeCurrencyValue = (e: any) => {
    setCurrencyValue(e.target.value);
  };

  return (
    <DialogSkeleton
      open={open}
      setOpen={setOpen}
      title={title}
      saveAction={handleSaveCurrencyRate}
      saveBtnText={okBtnText}
      closeBtnText={closeBtnText}
    >
      <div>{message}</div>
      <InputComponent
        numeric
        fullwidth
        value={currencyValue}
        onChange={onChangeCurrencyValue}
        name={""}
      />
    </DialogSkeleton>
  );
};

export default DialogSetCurrency;
