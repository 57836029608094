import { PageHeaderProps } from "../../interfaces/PageHeaderProps";
import { usePageHeaderStyle } from "../../styles/PageHeaderStyle";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";

const PageHeader: React.FC<PageHeaderProps> = ({ title, content }) => {
  const styles = usePageHeaderStyle();
  const kulcsUzletInvoicesUrl = () =>
    window.location.pathname.includes("quote")
      ? `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/quotes`
      : `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/invoices`;
  return (
    <div className={styles.container}>
      <BackIcon
        height="28px"
        width="28px"
        style={{ cursor: "pointer" }}
        onClick={() => window.location.replace(kulcsUzletInvoicesUrl())}
      />
      <div className={styles.title}>{title}</div>
      {content}
    </div>
  );
};

export default PageHeader;
