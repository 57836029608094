/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CustomerDefaultInvoicingType {
    BOTH = 'BOTH',
    PAPER = 'PAPER',
    ELECTRONIC = 'ELECTRONIC',
}
