/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaptionSettingsDto } from '../models/CaptionSettingsDto';
import type { CashbookSyncDto } from '../models/CashbookSyncDto';
import type { CashbookSyncedDto } from '../models/CashbookSyncedDto';
import type { ChangeVoucherProjectDto } from '../models/ChangeVoucherProjectDto';
import type { DefaultDocumentCommentsDto } from '../models/DefaultDocumentCommentsDto';
import type { DocumentProfileSettingsDto } from '../models/DocumentProfileSettingsDto';
import type { DocumentTemplateSettingsDto } from '../models/DocumentTemplateSettingsDto';
import type { DraftDto } from '../models/DraftDto';
import type { ExpenseResultDto } from '../models/ExpenseResultDto';
import type { InvoiceContentSettingsDto } from '../models/InvoiceContentSettingsDto';
import type { InvoiceDetailDto } from '../models/InvoiceDetailDto';
import type { InvoiceDto } from '../models/InvoiceDto';
import type { InvoiceEmailSettingsDto } from '../models/InvoiceEmailSettingsDto';
import type { InvoiceHeadDto } from '../models/InvoiceHeadDto';
import type { InvoiceNumberFormatSettingsDto } from '../models/InvoiceNumberFormatSettingsDto';
import type { InvoiceResultDto } from '../models/InvoiceResultDto';
import type { KobakErrorDto } from '../models/KobakErrorDto';
import type { KobakStatusDto } from '../models/KobakStatusDto';
import type { OpeningInventoryRequestDto } from '../models/OpeningInventoryRequestDto';
import type { QuoteDetailDto } from '../models/QuoteDetailDto';
import type { QuoteDto } from '../models/QuoteDto';
import type { QuoteHeadDto } from '../models/QuoteHeadDto';
import type { QuoteResultDto } from '../models/QuoteResultDto';
import type { ReceiveBatchPaymentDto } from '../models/ReceiveBatchPaymentDto';
import type { ReceivePaymentDto } from '../models/ReceivePaymentDto';
import type { ReceivePaymentResultDto } from '../models/ReceivePaymentResultDto';
import type { ResultDto } from '../models/ResultDto';
import type { SimpleModelDto } from '../models/SimpleModelDto';
import type { StockInDetailDto } from '../models/StockInDetailDto';
import type { StockInDto } from '../models/StockInDto';
import type { StockInHeadDto } from '../models/StockInHeadDto';
import type { StockMoveDetailDto } from '../models/StockMoveDetailDto';
import type { StockMoveDetailListItemDto } from '../models/StockMoveDetailListItemDto';
import type { StockMoveDetailListItemFilterDto } from '../models/StockMoveDetailListItemFilterDto';
import type { StockMoveDto } from '../models/StockMoveDto';
import type { StockMoveHeadDto } from '../models/StockMoveHeadDto';
import type { StockMoveResultDto } from '../models/StockMoveResultDto';
import type { UgyvitelSyncDto } from '../models/UgyvitelSyncDto';
import type { ValidatableInvoiceDto } from '../models/ValidatableInvoiceDto';
import type { VoucherCalculationDto } from '../models/VoucherCalculationDto';
import type { VoucherFromWorksheetDtoWorksheetResultDto } from '../models/VoucherFromWorksheetDtoWorksheetResultDto';
import type { VoucherType } from '../models/VoucherType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VoucherService {

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static getVoucherTechnicalproduct(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/technicalproduct',
        });
    }

    /**
     * @param pVoucherId 
     * @param pVoucherNumber 
     * @param pVoucherType 
     * @returns boolean Success
     * @throws ApiError
     */
    public static getVoucherVouchernumberexists(
pVoucherId?: number,
pVoucherNumber?: string,
pVoucherType?: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/vouchernumberexists',
            query: {
                'pVoucherId': pVoucherId,
                'pVoucherNumber': pVoucherNumber,
                'pVoucherType': pVoucherType,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postVoucherChangeproject(
requestBody?: ChangeVoucherProjectDto,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/changeproject',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pVoucherType 
     * @returns SimpleModelDto Success
     * @throws ApiError
     */
    public static getVoucherSimpledcustomers(
pVoucherType: string,
): CancelablePromise<Array<SimpleModelDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/simpledcustomers/{pVoucherType}',
            path: {
                'pVoucherType': pVoucherType,
            },
        });
    }

    /**
     * @param pVoucherType 
     * @returns SimpleModelDto Success
     * @throws ApiError
     */
    public static getVoucherSimpledproducts(
pVoucherType: string,
): CancelablePromise<Array<SimpleModelDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/simpledproducts/{pVoucherType}',
            path: {
                'pVoucherType': pVoucherType,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns DraftDto Success
     * @throws ApiError
     */
    public static postVoucherDraft(
requestBody?: DraftDto,
): CancelablePromise<DraftDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/draft',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DraftDto Success
     * @throws ApiError
     */
    public static getVoucherDraft(): CancelablePromise<Array<DraftDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/draft',
        });
    }

    /**
     * @param id 
     * @returns DraftDto Success
     * @throws ApiError
     */
    public static getVoucherDraft1(
id: number,
): CancelablePromise<DraftDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/draft/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param customerId 
     * @returns DraftDto Success
     * @throws ApiError
     */
    public static getVoucherDraftCustomer(
customerId: number,
): CancelablePromise<Array<DraftDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/draft/customer/{customerId}',
            path: {
                'customerId': customerId,
            },
        });
    }

    /**
     * @param id 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherDraftDelete(
id: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/draft/delete/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param pVoucherNumber 
     * @param pState 
     * @returns boolean Success
     * @throws ApiError
     */
    public static patchVoucherSyncinvoicestate(
pVoucherNumber?: string,
pState?: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/voucher/syncinvoicestate',
            query: {
                'pVoucherNumber': pVoucherNumber,
                'pState': pState,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static getVoucherHasunforwardedinvoices(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/hasunforwardedinvoices',
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static getVoucherGetnavserverstatus(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/getnavserverstatus',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getVoucherSyncunforwardedinvoices(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/syncunforwardedinvoices',
        });
    }

    /**
     * @param pId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static patchVoucherForwardinvoicebyid(
pId?: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/voucher/forwardinvoicebyid',
            query: {
                'pId': pId,
            },
        });
    }

    /**
     * @param id 
     * @returns boolean Success
     * @throws ApiError
     */
    public static getVoucherForwardinvoice(
id: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/forwardinvoice/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherForwardinvoice2(
id: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/forwardinvoice2/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param pVoucherNumber 
     * @returns boolean Success
     * @throws ApiError
     */
    public static patchVoucherForwardinvoicebynumber(
pVoucherNumber?: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/voucher/forwardinvoicebynumber',
            query: {
                'pVoucherNumber': pVoucherNumber,
            },
        });
    }

    /**
     * @returns KobakStatusDto Success
     * @throws ApiError
     */
    public static getVoucherForwardedinvoices(): CancelablePromise<Array<KobakStatusDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/forwardedinvoices',
        });
    }

    /**
     * @param transactionId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static getVoucherManualverification(
transactionId: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/manualverification/{transactionId}',
            path: {
                'transactionId': transactionId,
            },
        });
    }

    /**
     * @returns InvoiceResultDto Success
     * @throws ApiError
     */
    public static getVoucherInvoicemonitor(): CancelablePromise<InvoiceResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/invoicemonitor',
        });
    }

    /**
     * @returns ExpenseResultDto Success
     * @throws ApiError
     */
    public static getVoucherExpensemonitor(): CancelablePromise<ExpenseResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/expensemonitor',
        });
    }

    /**
     * @returns QuoteResultDto Success
     * @throws ApiError
     */
    public static getVoucherQuotemonitor(): CancelablePromise<QuoteResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/quotemonitor',
        });
    }

    /**
     * @returns StockMoveResultDto Success
     * @throws ApiError
     */
    public static getVoucherStockmovemonitor(): CancelablePromise<StockMoveResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/stockmovemonitor',
        });
    }

    /**
     * @param requestBody 
     * @returns InvoiceDto Success
     * @throws ApiError
     */
    public static postVoucherInvoicesync(
requestBody?: UgyvitelSyncDto,
): CancelablePromise<Array<InvoiceDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/invoicesync',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns KobakErrorDto Success
     * @throws ApiError
     */
    public static postVoucherKobakerrorsync(
requestBody?: Array<string>,
): CancelablePromise<Array<KobakErrorDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/kobakerrorsync',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param voucherType 
     * @returns VoucherFromWorksheetDtoWorksheetResultDto Success
     * @throws ApiError
     */
    public static getVoucherWorksheet(
id: number,
voucherType: number,
): CancelablePromise<VoucherFromWorksheetDtoWorksheetResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/worksheet/{id}/{voucherType}',
            path: {
                'id': id,
                'voucherType': voucherType,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns VoucherFromWorksheetDtoWorksheetResultDto Success
     * @throws ApiError
     */
    public static postVoucherWorksheetCreate(
requestBody?: Array<number>,
): CancelablePromise<VoucherFromWorksheetDtoWorksheetResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/worksheet/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns InvoiceHeadDto Success
     * @throws ApiError
     */
    public static getVoucherInvoices(): CancelablePromise<Array<InvoiceHeadDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/invoices',
        });
    }

    /**
     * @returns InvoiceHeadDto Success
     * @throws ApiError
     */
    public static getVoucherInvoicelistAllVoucherType(): CancelablePromise<Array<InvoiceHeadDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/invoicelist/allVoucherType',
        });
    }

    /**
     * @returns InvoiceHeadDto Success
     * @throws ApiError
     */
    public static getVoucherInvoicelist(): CancelablePromise<Array<InvoiceHeadDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/invoicelist',
        });
    }

    /**
     * @returns InvoiceHeadDto Success
     * @throws ApiError
     */
    public static getVoucherBizxinvoicelist(): CancelablePromise<Array<InvoiceHeadDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/bizxinvoicelist',
        });
    }

    /**
     * @param pInvoiceId 
     * @param pQuoteId 
     * @returns InvoiceHeadDto Success
     * @throws ApiError
     */
    public static getVoucherInvoice(
pInvoiceId?: number,
pQuoteId?: number,
): CancelablePromise<InvoiceHeadDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/invoice',
            query: {
                'pInvoiceId': pInvoiceId,
                'pQuoteId': pQuoteId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InvoiceResultDto Success
     * @throws ApiError
     */
    public static postVoucherInvoice(
requestBody?: InvoiceDto,
): CancelablePromise<InvoiceResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/invoice',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pInvoiceId 
     * @param pQuoteId 
     * @returns InvoiceDetailDto Success
     * @throws ApiError
     */
    public static getVoucherInvoicedetails(
pInvoiceId?: number,
pQuoteId?: number,
): CancelablePromise<Array<InvoiceDetailDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/invoicedetails',
            query: {
                'pInvoiceId': pInvoiceId,
                'pQuoteId': pQuoteId,
            },
        });
    }

    /**
     * @returns InvoiceHeadDto Success
     * @throws ApiError
     */
    public static getVoucherCashbookunsyncedinvoices(): CancelablePromise<Array<InvoiceHeadDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/cashbookunsyncedinvoices',
        });
    }

    /**
     * @returns CashbookSyncedDto Success
     * @throws ApiError
     */
    public static getVoucherCashboosyncedinvoices(): CancelablePromise<Array<CashbookSyncedDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/cashboosyncedinvoices',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherSyncinvoicestocashbook(
requestBody?: CashbookSyncDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/syncinvoicestocashbook',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherChecktechnicaluser(): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/checktechnicaluser',
        });
    }

    /**
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherChecktechnicaluserformanualupload(): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/checktechnicaluserformanualupload',
        });
    }

    /**
     * @param requestBody 
     * @returns InvoiceResultDto Success
     * @throws ApiError
     */
    public static postVoucherCreateinvoice(
requestBody?: ValidatableInvoiceDto,
): CancelablePromise<InvoiceResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/createinvoice',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pInvoiceId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherDeleteinvoice(
pInvoiceId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/deleteinvoice/{pInvoiceId}',
            path: {
                'pInvoiceId': pInvoiceId,
            },
        });
    }

    /**
     * @param pInvoiceId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherVoidinvoice(
pInvoiceId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/voidinvoice/{pInvoiceId}',
            path: {
                'pInvoiceId': pInvoiceId,
            },
        });
    }

    /**
     * @param pInvoiceId 
     * @returns InvoiceResultDto Success
     * @throws ApiError
     */
    public static getVoucherStornoinvoice(
pInvoiceId: number,
): CancelablePromise<InvoiceResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/stornoinvoice/{pInvoiceId}',
            path: {
                'pInvoiceId': pInvoiceId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postVoucherStornoinvoices(
requestBody?: Array<string>,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/stornoinvoices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pInvoiceId 
     * @returns any Success
     * @throws ApiError
     */
    public static getVoucherGenerateinvoicepreview(
pInvoiceId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/generateinvoicepreview/{pInvoiceId}',
            path: {
                'pInvoiceId': pInvoiceId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postVoucherGenerateinvoicepreviews(
requestBody?: Array<number>,
): CancelablePromise<Record<string, string>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/generateinvoicepreviews',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VoucherCalculationDto Success
     * @throws ApiError
     */
    public static getVoucherInvoicevalues(): CancelablePromise<VoucherCalculationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/invoicevalues',
        });
    }

    /**
     * @returns VoucherCalculationDto Success
     * @throws ApiError
     */
    public static getVoucherBizxinvoicevalues(): CancelablePromise<VoucherCalculationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/bizxinvoicevalues',
        });
    }

    /**
     * @param pInvoiceId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherCheckinvoicedetails(
pInvoiceId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/checkinvoicedetails/{pInvoiceId}',
            path: {
                'pInvoiceId': pInvoiceId,
            },
        });
    }

    /**
     * @param pCustomerCode 
     * @returns InvoiceHeadDto Success
     * @throws ApiError
     */
    public static getVoucherInvoices1(
pCustomerCode: string,
): CancelablePromise<Array<InvoiceHeadDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/invoices/{pCustomerCode}',
            path: {
                'pCustomerCode': pCustomerCode,
            },
        });
    }

    /**
     * @param voucherType 
     * @returns number Success
     * @throws ApiError
     */
    public static getVoucherAllidInvoice(
voucherType: VoucherType,
): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/allid/invoice/{voucherType}',
            path: {
                'voucherType': voucherType,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherStockin(
requestBody?: StockInDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/stockin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherSetopeninginventory(
requestBody?: OpeningInventoryRequestDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/setopeninginventory',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns InvoiceNumberFormatSettingsDto Success
     * @throws ApiError
     */
    public static getVoucherInvoicenumberformatsettings(): CancelablePromise<InvoiceNumberFormatSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/invoicenumberformatsettings',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherInvoicenumberformatsettings(
requestBody?: InvoiceNumberFormatSettingsDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/invoicenumberformatsettings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns InvoiceNumberFormatSettingsDto Success
     * @throws ApiError
     */
    public static getVoucherAllinvoicenumberformatsettings(): CancelablePromise<Array<InvoiceNumberFormatSettingsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/allinvoicenumberformatsettings',
        });
    }

    /**
     * @returns InvoiceNumberFormatSettingsDto Success
     * @throws ApiError
     */
    public static getVoucherAllstockmovenumberformatsettings(): CancelablePromise<Array<InvoiceNumberFormatSettingsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/allstockmovenumberformatsettings',
        });
    }

    /**
     * @param id 
     * @returns InvoiceNumberFormatSettingsDto Success
     * @throws ApiError
     */
    public static getVoucherInvoicenumberformatsetting(
id: number,
): CancelablePromise<InvoiceNumberFormatSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/invoicenumberformatsetting/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param pRestartEveryYear 
     * @returns number Success
     * @throws ApiError
     */
    public static getVoucherGetlatestinvoicenumber(
pRestartEveryYear: boolean,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/getlatestinvoicenumber/{pRestartEveryYear}',
            path: {
                'pRestartEveryYear': pRestartEveryYear,
            },
        });
    }

    /**
     * @returns CaptionSettingsDto Success
     * @throws ApiError
     */
    public static getVoucherCaptionsettings(): CancelablePromise<CaptionSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/captionsettings',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherCaptionsettings(
requestBody?: CaptionSettingsDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/captionsettings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param languageInternalCode 
     * @returns CaptionSettingsDto Success
     * @throws ApiError
     */
    public static getVoucherCaptionsettings1(
languageInternalCode: string,
): CancelablePromise<CaptionSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/captionsettings/{languageInternalCode}',
            path: {
                'languageInternalCode': languageInternalCode,
            },
        });
    }

    /**
     * @param languageInternalCode 
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherCaptionsettings1(
languageInternalCode: string,
requestBody?: CaptionSettingsDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/captionsettings/{languageInternalCode}',
            path: {
                'languageInternalCode': languageInternalCode,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns InvoiceContentSettingsDto Success
     * @throws ApiError
     */
    public static getVoucherInvoicecontentsettings(): CancelablePromise<InvoiceContentSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/invoicecontentsettings',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherInvoicecontentsettings(
requestBody?: InvoiceContentSettingsDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/invoicecontentsettings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DocumentProfileSettingsDto Success
     * @throws ApiError
     */
    public static getVoucherDocumentprofilesettings(): CancelablePromise<DocumentProfileSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/documentprofilesettings',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherDocumentprofilesettings(
requestBody?: DocumentProfileSettingsDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/documentprofilesettings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns InvoiceEmailSettingsDto Success
     * @throws ApiError
     */
    public static getVoucherInvoiceemailsettings(): CancelablePromise<InvoiceEmailSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/invoiceemailsettings',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherInvoiceemailsettings(
requestBody?: InvoiceEmailSettingsDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/invoiceemailsettings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DefaultDocumentCommentsDto Success
     * @throws ApiError
     */
    public static getVoucherDefaultdocumentcomments(): CancelablePromise<DefaultDocumentCommentsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/defaultdocumentcomments',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherDefaultdocumentcomments(
requestBody?: DefaultDocumentCommentsDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/defaultdocumentcomments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DocumentTemplateSettingsDto Success
     * @throws ApiError
     */
    public static getVoucherDocumenttemplatesettings(): CancelablePromise<Array<DocumentTemplateSettingsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/documenttemplatesettings',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherDocumenttemplatesettings(
requestBody?: DocumentTemplateSettingsDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/documenttemplatesettings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pInvoiceId 
     * @returns ReceivePaymentDto Success
     * @throws ApiError
     */
    public static getVoucherReceivepayments(
pInvoiceId: number,
): CancelablePromise<Array<ReceivePaymentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/receivepayments/{pInvoiceId}',
            path: {
                'pInvoiceId': pInvoiceId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ReceivePaymentResultDto Success
     * @throws ApiError
     */
    public static postVoucherReceivepayment(
requestBody?: ReceivePaymentDto,
): CancelablePromise<ReceivePaymentResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/receivepayment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherBatchReceivepayments(
requestBody?: ReceiveBatchPaymentDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/batch/receivepayments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherBatchReceivePaymentsByIds(
requestBody?: Array<number>,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/batch/receivePaymentsByIds',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns ReceivePaymentResultDto Success
     * @throws ApiError
     */
    public static getVoucherDeletereceivepayment(
pId: number,
): CancelablePromise<ReceivePaymentResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/deletereceivepayment/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param pExpenseId 
     * @returns ReceivePaymentDto Success
     * @throws ApiError
     */
    public static getVoucherReceivepaymentsbyexpense(
pExpenseId: number,
): CancelablePromise<Array<ReceivePaymentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/receivepaymentsbyexpense/{pExpenseId}',
            path: {
                'pExpenseId': pExpenseId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ReceivePaymentResultDto Success
     * @throws ApiError
     */
    public static postVoucherReceivepaymentofexpense(
requestBody?: ReceivePaymentDto,
): CancelablePromise<ReceivePaymentResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/receivepaymentofexpense',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns ReceivePaymentResultDto Success
     * @throws ApiError
     */
    public static getVoucherDeletereceivepaymentofexpense(
pId: number,
): CancelablePromise<ReceivePaymentResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/deletereceivepaymentofexpense/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @returns StockInHeadDto Success
     * @throws ApiError
     */
    public static getVoucherExpenses(): CancelablePromise<Array<StockInHeadDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/expenses',
        });
    }

    /**
     * @returns StockInHeadDto Success
     * @throws ApiError
     */
    public static getVoucherExpensesChart(): CancelablePromise<Array<StockInHeadDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/expenses/chart',
        });
    }

    /**
     * @param pExpenseId 
     * @returns StockInHeadDto Success
     * @throws ApiError
     */
    public static getVoucherExpense(
pExpenseId: number,
): CancelablePromise<StockInHeadDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/expense/{pExpenseId}',
            path: {
                'pExpenseId': pExpenseId,
            },
        });
    }

    /**
     * @param pExpenseId 
     * @returns StockInDetailDto Success
     * @throws ApiError
     */
    public static getVoucherExpensedetails(
pExpenseId: number,
): CancelablePromise<Array<StockInDetailDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/expensedetails/{pExpenseId}',
            path: {
                'pExpenseId': pExpenseId,
            },
        });
    }

    /**
     * @param pExpenseId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherCheckexpensedetails(
pExpenseId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/checkexpensedetails/{pExpenseId}',
            path: {
                'pExpenseId': pExpenseId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ExpenseResultDto Success
     * @throws ApiError
     */
    public static postVoucherExpense(
requestBody?: StockInDto,
): CancelablePromise<ExpenseResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/expense',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param expenseId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherExpenseDeleteimage(
expenseId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/expense/deleteimage/{expenseId}',
            path: {
                'expenseId': expenseId,
            },
        });
    }

    /**
     * @param pExpenseId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherDeleteexpense(
pExpenseId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/deleteexpense/{pExpenseId}',
            path: {
                'pExpenseId': pExpenseId,
            },
        });
    }

    /**
     * @param pExpenseId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherVoidexpense(
pExpenseId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/voidexpense/{pExpenseId}',
            path: {
                'pExpenseId': pExpenseId,
            },
        });
    }

    /**
     * @param pRestartEveryYear 
     * @returns number Success
     * @throws ApiError
     */
    public static getVoucherGetlatestexpensenumber(
pRestartEveryYear: boolean,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/getlatestexpensenumber/{pRestartEveryYear}',
            path: {
                'pRestartEveryYear': pRestartEveryYear,
            },
        });
    }

    /**
     * @param pExpenseId 
     * @param pVoucherNumber 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherStornoexpense(
pExpenseId: number,
pVoucherNumber?: string,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/stornoexpense/{pExpenseId}',
            path: {
                'pExpenseId': pExpenseId,
            },
            query: {
                'pVoucherNumber': pVoucherNumber,
            },
        });
    }

    /**
     * @returns VoucherCalculationDto Success
     * @throws ApiError
     */
    public static getVoucherExpensevalues(): CancelablePromise<VoucherCalculationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/expensevalues',
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static getVoucherAllidExpense(): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/allid/expense',
        });
    }

    /**
     * @returns QuoteHeadDto Success
     * @throws ApiError
     */
    public static getVoucherQuotes(): CancelablePromise<Array<QuoteHeadDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/quotes',
        });
    }

    /**
     * @param pQuoteId 
     * @returns QuoteHeadDto Success
     * @throws ApiError
     */
    public static getVoucherQuote(
pQuoteId: number,
): CancelablePromise<QuoteHeadDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/quote/{pQuoteId}',
            path: {
                'pQuoteId': pQuoteId,
            },
        });
    }

    /**
     * @param pQuoteId 
     * @returns QuoteDetailDto Success
     * @throws ApiError
     */
    public static getVoucherQuotedetails(
pQuoteId: number,
): CancelablePromise<Array<QuoteDetailDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/quotedetails/{pQuoteId}',
            path: {
                'pQuoteId': pQuoteId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns QuoteResultDto Success
     * @throws ApiError
     */
    public static postVoucherQuote(
requestBody?: QuoteDto,
): CancelablePromise<QuoteResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/quote',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pRestartEveryYear 
     * @returns number Success
     * @throws ApiError
     */
    public static getVoucherGetlatestquotenumber(
pRestartEveryYear: boolean,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/getlatestquotenumber/{pRestartEveryYear}',
            path: {
                'pRestartEveryYear': pRestartEveryYear,
            },
        });
    }

    /**
     * @param pQuoteId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherVoidquote(
pQuoteId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/voidquote/{pQuoteId}',
            path: {
                'pQuoteId': pQuoteId,
            },
        });
    }

    /**
     * @returns VoucherCalculationDto Success
     * @throws ApiError
     */
    public static getVoucherQuotevalues(): CancelablePromise<VoucherCalculationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/quotevalues',
        });
    }

    /**
     * @param pQuoteId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getVoucherCheckquotedetails(
pQuoteId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/checkquotedetails/{pQuoteId}',
            path: {
                'pQuoteId': pQuoteId,
            },
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static getVoucherGetlastquotename(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/getlastquotename',
        });
    }

    /**
     * @param webshopQuoteName 
     * @returns string Success
     * @throws ApiError
     */
    public static getVoucherGetlastquotename1(
webshopQuoteName: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/getlastquotename/{webshopQuoteName}',
            path: {
                'webshopQuoteName': webshopQuoteName,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static getVoucherAllidQuote(): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/allid/quote',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postVoucherCreateInvoiceFromQuotes(
requestBody?: Array<number>,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/createInvoiceFromQuotes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns StockMoveHeadDto Success
     * @throws ApiError
     */
    public static getVoucherStockmovelist(): CancelablePromise<Array<StockMoveHeadDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/stockmovelist',
        });
    }

    /**
     * @param requestBody 
     * @returns StockMoveDetailListItemDto Success
     * @throws ApiError
     */
    public static postVoucherStockmovedetaillist(
requestBody?: StockMoveDetailListItemFilterDto,
): CancelablePromise<Array<StockMoveDetailListItemDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/stockmovedetaillist',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns StockMoveResultDto Success
     * @throws ApiError
     */
    public static postVoucherStockmove(
requestBody?: StockMoveDto,
): CancelablePromise<StockMoveResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/voucher/stockmove',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pStockMoveId 
     * @returns StockMoveHeadDto Success
     * @throws ApiError
     */
    public static getVoucherStockmove(
pStockMoveId?: number,
): CancelablePromise<StockMoveHeadDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/stockmove',
            query: {
                'pStockMoveId': pStockMoveId,
            },
        });
    }

    /**
     * @param pStockMoveId 
     * @returns StockMoveDetailDto Success
     * @throws ApiError
     */
    public static getVoucherStockmovedetails(
pStockMoveId?: number,
): CancelablePromise<Array<StockMoveDetailDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/voucher/stockmovedetails',
            query: {
                'pStockMoveId': pStockMoveId,
            },
        });
    }

}
