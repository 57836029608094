import { useId, Option, Combobox } from "@fluentui/react-components";
import { SelectProps } from "../../interfaces/SelectProps";
import { useContext, useEffect, useState } from "react";
import { DeviceContext } from "../../utils/GlobalContexts";

const SelectComponent: React.FC<SelectProps> = ({
  options,
  label,
  action,
  small,
  hasAllOption,
  defaultValue,
  transparent,
  value,
  fullwidth,
  noEmptyOption,
  disabled,
  autoWidth,
}) => {
  const selectId = useId();
  const { isMobile } = useContext(DeviceContext);
  const [searchValue, setSearchValue] = useState<undefined | string>(
    value ?? "Üres..."
  );

  useEffect(() => {
    setSearchValue(value ?? "Üres...");
  }, [value]);

  const blurEvent = (e: any) => setSearchValue(value);

  const changeEvent = (e: any) => setSearchValue(e.target.value?.trim());

  return (
    <span
      style={
        small
          ? {
              width: small && transparent && isMobile ? "100px" : "100%",
              justifyContent: "space-between",
              display: "flex",
              margin: "10px 0px",
            }
          : autoWidth
          ? {
              width: "100%",
              justifyContent: "space-between",
              display: "flex",
              margin: "10px 0px",
            }
          : {}
      }
    >
      <label style={{ margin: "auto", marginLeft: 0 }} htmlFor={selectId}>
        {label}
      </label>

      <Combobox
        disabled={disabled}
        height={200}
        size={small ? "small" : "medium"}
        style={
          isMobile && transparent
            ? { minWidth: "100px", background: "transparent", border: "none" }
            : fullwidth
            ? {
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxHeight: "200px",
              }
            : autoWidth && !isMobile
            ? { width: "auto" }
            : isMobile
            ? { minWidth: "100px" }
            : small
            ? {}
            : {
                minWidth: "100px",
                width: "100px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }
        }
        id={selectId}
        onOptionSelect={action}
        defaultValue={
          value
            ? value
            : defaultValue
            ? defaultValue
            : hasAllOption
            ? "Összes"
            : "Üres..."
        }
        value={searchValue ?? value ?? "Üres..."}
        onChange={changeEvent}
        onBlur={blurEvent}
      >
        {!noEmptyOption && (
          <Option
            key={-1}
            value={undefined}
            text={hasAllOption ? "Összes" : "Üres..."}
          >
            <div>{hasAllOption ? "Összes" : "Üres..."}</div>
          </Option>
        )}
        {options?.map((option) => (
          <Option
            style={{ height: "15px" }}
            key={(option as any).Id}
            value={option}
            text={(option as any).DisplayName ?? (option as any).Name}
          >
            <div>{(option as any).DisplayName ?? (option as any).Name}</div>
          </Option>
        ))}
      </Combobox>
    </span>
  );
};
export default SelectComponent;
