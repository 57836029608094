import {
  CurrencyDto,
  PaymentMethodDto,
  QuoteDetailDto,
  QuoteDto,
} from "../KulcsUzletApi";
import { VatDictionary } from "../models/Dictionaries";
import {
  calculatePercentage,
  formatNumberWithQuantityUnit,
  getvat,
  needToRoundTo5,
} from "./HelperFunctions";

export const getQuoteTotal = (
  quote: QuoteDto | undefined,
  paymentMethod: undefined | PaymentMethodDto,
  currency: CurrencyDto | undefined,
  vats: null | VatDictionary
) => {
  const total = getGrossValueSum(quote, vats);
  return formatNumberWithQuantityUnit(
    total,
    currency,
    currency?.GrandTotalDigits,
    needToRoundTo5(paymentMethod, currency)
  );
};

const getGrossValue = (
  detail: QuoteDetailDto,
  vatpercent: number | undefined,
  quote: QuoteDto | undefined
) => {
  return (
    getNetValue(
      calculatePercentage(
        detail?.UnitPrice,
        100 - (detail?.DiscountPercent ?? 0)
      ),
      detail.Quantity
    ) *
    (1 + (vatpercent ?? 0) / 100)
  );
};

const getGrossValueSum = (
  quote: QuoteDto | undefined,
  vats: null | VatDictionary
) => {
  if (!quote?.Items || quote?.Items?.length === 0) return 0;

  let sum = 0;
  quote?.Items.map(
    (detail: QuoteDetailDto) =>
      (sum += getGrossValue(
        detail,
        vats && detail.Vat ? vats[detail.Vat]?.Rate : undefined,
        quote
      ))
  );
  return sum;
};

export const getNetValue = (
  unitprice: number,
  quantity: number | undefined | null
) => unitprice * (quantity ?? 0);

export const getQuoteNetValueSum = (quote: QuoteDto | undefined) => {
  if (!quote?.Items || quote?.Items?.length === 0) return 0;

  let sum = 0;
  quote?.Items.map(
    (detail) =>
      (sum += getNetValue(
        calculatePercentage(
          detail?.UnitPrice,
          100 - (detail?.DiscountPercent ?? 0)
        ),
        detail.Quantity ?? 0
      )),
    0
  );
  return sum;
};

export const getQuoteVatValueSum = (
  quote: QuoteDto | undefined,
  vats: null | VatDictionary
) => {
  if (!quote?.Items || quote?.Items?.length === 0) return 0;

  let sum = 0;
  quote?.Items.map(
    (detail) =>
      (sum += calculatePercentage(
        getNetValue(
          calculatePercentage(
            detail?.UnitPrice,
            100 - (detail?.DiscountPercent ?? 0)
          ),
          detail.Quantity
        ),
        getvat(vats, detail.Vat)
      )),
    0
  );

  return sum;
};
