import * as React from "react";
import DialogSkeleton from "./DialogSkeleton";
import { DialogProps } from "../../interfaces/DialogConfirmProps";
import InputComponent from "../input/InputComponent";
import { useState } from "react";
import { Checkbox, Label, Textarea } from "@fluentui/react-components";
import { useStylesDialogEditCustomerStyle } from "../../styles/DialogEditCustomerStyle";
import {
  DeviceContext,
  MasterDataContext,
  QuoteContext,
} from "../../utils/GlobalContexts";
import {
  DocumentPrintService,
  MasterDataService,
  SendDocumentRequestDto,
  UserSettingsService,
} from "../../KulcsUzletApi";
import InfoIcon from "../icons/InfoIcon";
import _ from "lodash";

const DialogSendEmail: React.FC<DialogProps> = ({
  title,
  message,
  open,
  setOpen,
  handleAction,
  okBtnText,
  closeBtnText,
  currencyRate,
  setCurrencyRate,
  handleSaveQuote,
  openResultModal,
}) => {
  const [email, setEmail] = useState<SendDocumentRequestDto | undefined>(
    undefined
  );
  const styles = useStylesDialogEditCustomerStyle();
  const { isMobile } = React.useContext(DeviceContext);
  const { quote } = React.useContext(QuoteContext);
  const [showTooltips, setShowToolTips] = useState([] as string[]);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const { userSettings } = React.useContext(MasterDataContext);

  React.useEffect(() => {
    if (open) getDefaultEmail();
    else {
      setEmail(undefined);
      setIsSaveLoading(false);
    }
  }, [open]);

  const getDefaultEmail = async () => {
    let companyName: string = "";
    await MasterDataService.getMasterdataCompanyprofile()
      .then((res) => {
        companyName = res.CompanyName ?? "";
      })
      .catch(() => {});

    let emailBody = userSettings?.InvoiceEmailBodyText?.replace(
      /\[Partner neve\]/g,
      quote?.CustomerObj?.Name ?? ""
    ).replace(/\[Cegnev\]/g, companyName);

    let emailSubject = "{{title}} érkezett {{company}} partneredtől!"
      .replace(/\{\{company\}\}/g, companyName)
      .replace(/\{\{title\}\}/g, quote?.QuoteName ?? ("Ajánlat" as string));

    setEmail({
      VoucherNumber: quote.VoucherNumber,
      VoucherId: 0,
      VoucherType: 6,
      VoucherTitle: quote?.QuoteName ?? "Ajánlat",
      ClientEmail: quote?.CustomerObj?.Email,
      ClientEmails: [] as string[],
      EmailSubject: emailSubject,
      EmailBody: emailBody,
      toIssuer: false,
      toAccountant: false,
      IsEinvoice: false,
    } as SendDocumentRequestDto);
  };

  const handleSendEmail = async () => {
    if (handleSaveQuote !== undefined) {
      setIsSaveLoading(true);

      let voucherid = await handleSaveQuote(true);

      if (voucherid) {
        let updatedDto = _.cloneDeep(email);
        if (updatedDto) updatedDto.VoucherId = voucherid;
        await DocumentPrintService.postDocumentprintSendvoucher(updatedDto)
          .then((res) => {
            if (res.IsSuccess) {
              setOpen(false);
              openResultModal &&
                openResultModal("Az email kiküldése sikeres volt.");
              window.location.replace(
                `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/quotes`
              );
            } else {
              openResultModal &&
                openResultModal(
                  `Az árajánlat mentése nem sikerült: ${res?.ErrorMsg ?? ""}`
                );
              setIsSaveLoading(false);
            }
          })
          .catch((e) => {
            openResultModal &&
              openResultModal(
                `Az árajánlat mentése nem sikerült: ${e?.message}`
              );
            setIsSaveLoading(false);
          });
      }
    }
  };

  const onChangeInput = (e: any, data: any) => {
    setEmail((prev: any) => ({ ...prev, [e.target.name]: data.value }));
  };

  const addItemToRequiredFields = (item: string) => {
    let fields = [] as string[];
    fields.push(item);
    setShowToolTips(fields);
  };

  React.useEffect(() => {
    if (showTooltips?.length > 0) {
      setTimeout(() => {
        setShowToolTips([]);
      }, 3000);
    }
  }, [showTooltips]);

  return (
    <DialogSkeleton
      open={open}
      setOpen={setOpen}
      title={title}
      saveAction={handleSendEmail}
      saveBtnText={okBtnText}
      closeBtnText={closeBtnText}
      isSaveLoading={isSaveLoading}
    >
      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Email cím
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <InputComponent
            fullwidth
            value={email?.ClientEmail ?? ""}
            onChange={onChangeInput}
            name={"ClientEmail"}
            showTooltip={showTooltips?.includes("Email")}
            tooltip="Több email cím is megadható pontosvesszővel (;) elválasztva."
            tooltipColor="#36bd3f"
            iconCount={1}
            icon={<InfoIcon onClick={() => addItemToRequiredFields("Email")} />}
          />
        </div>
      </div>
      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Tárgy
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <InputComponent
            fullwidth
            value={email?.EmailSubject ?? ""}
            onChange={onChangeInput}
            name={"EmailSubject"}
          />
        </div>
      </div>

      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Email szövege
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <Textarea
            style={{
              width: "100%",
              height: isMobile ? "150px" : "200px",
              overflow: "hidden",
            }}
            value={email?.EmailBody ?? ""}
            onChange={onChangeInput}
            name={"EmailBody"}
          />
        </div>
      </div>
      <div
        className={isMobile ? styles.mobileline : styles.line}
        style={{ marginTop: isMobile ? "150px" : "160px" }}
      >
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Másolatot kér
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <Checkbox
            checked={email?.toIssuer ?? false}
            onChange={(ev, data) =>
              setEmail((prev: any) => ({ ...prev, toIssuer: data.checked }))
            }
          />
        </div>{" "}
      </div>
    </DialogSkeleton>
  );
};

export default DialogSendEmail;
