import { makeStyles, shorthands } from "@fluentui/react-components";
import { COLOR } from "../utils/Enums";

export const useStylesListContainer = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "630px",
    overflowY: "auto",
  },
  mobileContainer: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.overflow("auto"),
  },
  article: {
    display: "flex",
    alignItems: "stretch",
    backgroundColor: "rgba(221, 232, 241, 0.75)",
    marginBottom: "5px",
    ...shorthands.border("4px", "solid", "transparent"),
    cursor: "pointer",
    ":hover": {
      ...shorthands.border("4px", "solid", COLOR.BIZ_DARK_ORANGE),
      backgroundColor: "rgba(63, 118, 187, 0.3)",
    },
    ...shorthands.transition("all", "0.3s", "ease-in-out"),
  },
  mobileArticle: {
    display: "flex",
    alignItems: "stretch",
    backgroundColor: "rgba(221, 232, 241, 0.75)",

    ...shorthands.border("4px", "solid", "transparent"),

    cursor: "pointer",
    ...shorthands.transition("all", "0.3s", "ease-in-out"),
  },
  highlightedArticle: {
    display: "flex",
    alignItems: "stretch",
    marginBottom: "5px",
    cursor: "pointer",
    ...shorthands.border("4px", "solid", COLOR.BIZ_DARK_ORANGE),
    backgroundColor: "rgba(63, 118, 187, 0.7)",
    ...shorthands.transition("all", "0.3s", "ease-in-out"),
  },
  image: {
    position: "relative",
    width: "70px !important",
    height: "70px",
    color: "#ffffff",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#939393",
  },
  clientcode: {
    width: "70px",
    ...shorthands.overflow("hidden"),
    fontSize: "12px",
    position: "absolute",
    bottom: "0",
    color: "#fff",
    textAlign: "center",
    display: "block",
    height: "17px",
  },
  client: {
    flexGrow: "1",
    ...shorthands.padding("0", "5px", "0", "10px"),
    display: "flex",
    flexDirection: "column",
  },
  newClientText: { justifyContent: "center", textAlign: "center" },
  name: {
    fontWeight: "normal",
    ...shorthands.overflow("hidden"),
    // whiteSpace: "nowrap",
    lineBreak: "anywhere",
    textOverflow: "ellipsis",
  },
  bottomLine: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    marginTop: "auto",
  },
  quantityunit: { marginRight: "auto" },
  price: {},
  listName: {
    fontWeight: 300,
    // ...shorthands.margin("5px"),
    marginTop: "15px",
    marginBottom: "15px",
  },
  newClientButton: {
    lineHeight: "35px",
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
  },
});
