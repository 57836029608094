import { makeStyles, shorthands } from "@fluentui/react-components";
import { COLOR } from "../utils/Enums";

export const useStylesMainPage = makeStyles({
  container: {
    paddingLeft: "3%",
    paddingRight: "3%",
    marginBottom: "20px",
  },
  containerMobile: {
    ...shorthands.padding("10px", "0px", "10px", "0px"),
  },
  invoiceContainerMobile: {
    ...shorthands.margin("0", "3% !important"),
    paddingBottom: "55px !important",
  },
  innerContainer: {
    width: "100%",
    display: "flex",
  },
  containerLeft: {
    width: "33.33333333%",
    marginRight: "15px",
  },
  containerRight: {
    backgroundColor: COLOR.BIZ_LIGHT_BLUE,
    position: "relative",
    width: "66%",
    marginLeft: "15px",
    ...shorthands.borderRadius("5px"),
  },
});
