import { useStylesIcon } from "../../styles/IconStyle";
import { COLOR } from "../../utils/Enums";

type Props = {
  onClick: () => void;
};

const InfoIcon = ({ onClick }: Props) => {
  const styles = useStylesIcon();
  return (
    <span onClick={onClick} className={styles.container}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        fill={"#000000"}
        viewBox="0 0 192 512"
      >
        <path d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z" />
      </svg>
    </span>
  );
};

export default InfoIcon;
