import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import AuthPage from "./pages/AuthPage";
import { msalInstance } from "./utils/auth/AuthProvider";

export default function Auth({ children }: { children: React.ReactNode }) {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthPage />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
}
