/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BizVoucherType {
    INVOICE = 'Invoice',
    EXPENSE = 'Expense',
    QUOTE = 'Quote',
    BIZXPERT_INVOICE = 'BizxpertInvoice',
}
