import CardFlip from "../card-flip/CardFlip";
import Comments from "../comments/Comments";
import InputComponent from "../input/InputComponent";
import { Button, Label } from "@fluentui/react-components";
import { COLOR } from "../../utils/Enums";
import { useStylesInvoiceForm } from "../../styles/InvoiceFormStyles";
import {
  formatNumberWithQuantityUnit,
  getCustomerAddress,
} from "../../utils/HelperFunctions";
import { MasterDataContext, QuoteContext } from "../../utils/GlobalContexts";
import {
  CurrencyDto,
  LanguageDto,
  PaymentMethodDto,
  ProjectDto,
  QuoteDto,
  TransportModeDto,
} from "../../KulcsUzletApi";
import { Fragment, useContext, useState } from "react";
import SelectComponent from "../select/SelectComponent";
import { DatePicker, DayOfWeek } from "@fluentui/react-datepicker-compat";
import { DayPickerStrings } from "../../utils/GlobalConsts";
import { QuoteFormProps } from "../../interfaces/QuoteFormProps";
import QuoteDetailTable from "../detail-table/QuoteDetailTable";
import {
  getQuoteNetValueSum,
  getQuoteTotal,
  getQuoteVatValueSum,
} from "../../utils/QuoteFunctions";
import ClipLoader from "react-spinners/ClipLoader";

const QuoteForm: React.FC<QuoteFormProps> = ({
  openCustomerModal,
  openResultModal,
  updateCurrencyRate,
  selectDetail,
  setShouldUpdateCurrency,
  currencyRate,
  copy,
  handleSendEmailClick,
  handleSave,
  isSaveLoading,
  isLoading,
  handleOpenPreview,
  isPreviewLoading,
}) => {
  const styles = useStylesInvoiceForm();
  const [isFlipped, setIsFlipped] = useState(false);
  const handleFlip = () => setIsFlipped(!isFlipped);
  const { quote, setQuote } = useContext(QuoteContext);
  const {
    projects,
    paymentMethods,
    currencies,
    vats,
    transportModes,
    languages,
  } = useContext(MasterDataContext);
  const [quoteDiscount, setQuoteDiscount] = useState(0);

  const changeDateProp = (date: Date | null | undefined, property: string) =>
    setQuote((prev: QuoteDto) => ({
      ...prev,
      [property]: date,
    }));

  const editSelectPaymentMethod = async (ev: any, data: any) => {
    setQuote((prev: QuoteDto) => ({
      ...prev,
      PaymentMethod: (data.optionValue as PaymentMethodDto)?.Id ?? undefined,
      PaymentMethodDelayDay:
        (data.optionValue as PaymentMethodDto)?.DelayDays ?? undefined,
    }));
  };

  const editSelectProject = async (ev: any, data: any) => {
    setQuote((prev: QuoteDto) => ({
      ...prev,
      ProjectId: (data.optionValue as ProjectDto)?.Id ?? undefined,
    }));
  };

  const editSelectTransport = async (ev: any, data: any) => {
    setQuote((prev: QuoteDto) => ({
      ...prev,
      TransportMode: (data.optionValue as TransportModeDto)?.Id ?? undefined,
    }));
  };

  const editSelectLanguage = async (ev: any, data: any) => {
    setQuote((prev: QuoteDto) => ({
      ...prev,
      LanguageInternalCode:
        (data.optionValue as LanguageDto)?.InternalCode ?? undefined,
    }));
  };

  const editSelectCurrency = async (ev: any, data: any) => {
    setQuote((prev: QuoteDto) => ({
      ...prev,
      Currency: (data.optionValue as CurrencyDto)?.Id ?? undefined,
    }));
    setShouldUpdateCurrency(true);
  };

  const updateQuoteDiscount = (e: any) => {
    let value = e.target.value;
    setQuoteDiscount(value);
  };

  const updateQuote = (e: any) => {
    let discount = quoteDiscount;

    if (discount < 0) discount = 0;
    else if (discount > 100) discount = 100;
    setQuoteDiscount(discount);

    setQuote((prev: QuoteDto) => ({
      ...prev,
      DiscountPercent: discount,
      Items: prev?.Items?.map((x) => ({
        ...x,
        DiscountPercent: discount * 1,
      })),
    }));
  };

  function changeCurrencyRate(event: any): void {
    updateCurrencyRate();
  }

  const handleSend = async () => {
    if (!quote?.Customer || quote?.Items?.length === 0) {
      openResultModal("Legalább 1 termék és 1 ügyfél kiválasztása kötelező!");
      return;
    }
    handleSendEmailClick();
  };

  return (
    <CardFlip isFlipped={isFlipped}>
      <Fragment>
        <div className={styles.floatRight} style={{ display: "block" }}>
          <div className={styles.alignRight}>
            <Button
              disabled={isPreviewLoading}
              className={styles.button}
              style={{ backgroundColor: COLOR.BIZ_PURPLE }}
              appearance="primary"
              onClick={handleOpenPreview}
            >
              {isPreviewLoading ? (
                <ClipLoader size={16} color="white" />
              ) : (
                "ELŐNÉZET"
              )}
            </Button>

            <Button
              disabled={isSaveLoading}
              className={styles.button}
              style={{ backgroundColor: COLOR.BIZ_DARK_ORANGE }}
              appearance="primary"
              onClick={() => (handleSave ? handleSave() : {})}
            >
              {isSaveLoading ? (
                <ClipLoader size={16} color="white" />
              ) : (
                "MENTÉS"
              )}
            </Button>

            <Button
              disabled={isSaveLoading}
              className={styles.button}
              style={{ backgroundColor: COLOR.BIZ_DARK_ORANGE }}
              appearance="primary"
              onClick={handleSend}
            >
              {"KÜLDÉS"}
            </Button>
          </div>

          <div className={styles.alignRight}></div>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              id="client-info-wrapper"
              className={styles.client}
              style={{ width: "48%", boxSizing: "border-box" }}
            >
              <div
                id={"client-info"}
                style={
                  !quote?.CustomerObj?.Id || quote?.Status === 5
                    ? { display: "none" }
                    : {}
                }
              >
                <div onClick={openCustomerModal}>
                  <div
                    className="btn-circle-icon"
                    style={{
                      display: "inline-flex",
                      marginLeft: "50px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="36px"
                      fill={COLOR.BIZ_BLACK}
                      viewBox="0 0 448 380"
                    >
                      <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                    </svg>
                  </div>
                  <div style={{ fontFamily: "Oxygen", fontSize: "17px" }}>
                    {"Partner szerkesztése"}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "inline-flex",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill={COLOR.BIZ_DARK_ORANGE}
                  height="42px"
                  viewBox="0 0 448 512"
                  style={!quote?.CustomerObj?.Id ? { opacity: 0 } : {}}
                >
                  <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                </svg>

                <div
                  className="client-info-wrapper"
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <h3 className={styles.clientInfo}>
                    {quote?.CustomerObj?.Name ?? ""}
                  </h3>
                  <div className={styles.clientInfo}>
                    {getCustomerAddress(quote?.CustomerObj)}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.alignRight}>
                <h2 className={styles.vouchernumber}>{quote?.VoucherNumber}</h2>
              </div>
              <div className={styles.alignRight}>
                <div className={styles.label}>
                  <Label size="small">Iktatószám</Label>
                </div>
                <InputComponent
                  disabled={quote?.Status === 5}
                  maxLength={80}
                  value={quote?.FilingNumber ?? ""}
                  name="FilingNumber"
                  onChange={(e: any) =>
                    setQuote((prev: QuoteDto) => ({
                      ...prev,
                      FilingNumber: e.target.value,
                    }))
                  }
                />
                <div className={styles.label}>
                  <Label size="small">Érvényesség</Label>
                </div>
                <DatePicker
                  firstDayOfWeek={DayOfWeek.Monday}
                  disabled={quote?.Status === 5}
                  contentAfter={<></>}
                  style={{
                    width: "100px",
                  }}
                  onSelectDate={(data: any) =>
                    changeDateProp(data, "DeliveryDate")
                  }
                  value={
                    quote?.DeliveryDate
                      ? new Date(quote?.DeliveryDate)
                      : new Date()
                  }
                  allowTextInput
                  isMonthPickerVisible={true}
                  strings={DayPickerStrings}
                  formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
                />
              </div>
              <div className={styles.alignRight}>
                <div className={styles.label}>
                  <Label size="small">Projekt</Label>
                </div>
                <SelectComponent
                  disabled={quote?.Status === 5}
                  options={projects && (Object.values(projects) as any)}
                  value={
                    (projects &&
                      quote?.ProjectId &&
                      projects[quote?.ProjectId]?.Name) ??
                    ""
                  }
                  action={editSelectProject}
                />

                <div className={styles.label}>
                  <Label size="small">Pénznem</Label>
                </div>
                <SelectComponent
                  disabled={quote?.Status === 5}
                  noEmptyOption
                  options={currencies && (Object.values(currencies) as any)}
                  value={
                    (currencies &&
                      quote?.Currency &&
                      currencies[quote?.Currency]?.DisplayName) ??
                    ""
                  }
                  action={editSelectCurrency}
                />
              </div>
              <div className={styles.alignRight}>
                <div className={styles.label}>
                  <Label size="small">Kelte</Label>
                </div>
                <DatePicker
                  firstDayOfWeek={DayOfWeek.Monday}
                  disabled={quote?.Status === 5}
                  isMonthPickerVisible={true}
                  strings={DayPickerStrings}
                  contentAfter={<></>}
                  style={{ width: "100px" }}
                  onSelectDate={(data: any) =>
                    changeDateProp(data, "VoucherDate")
                  }
                  value={
                    quote?.VoucherDate
                      ? new Date(quote?.VoucherDate)
                      : new Date()
                  }
                  allowTextInput
                  formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
                />
                <div className={styles.label}>
                  <Label size="small">Szállítási határidő</Label>
                </div>
                <DatePicker
                  firstDayOfWeek={DayOfWeek.Monday}
                  disabled={quote?.Status === 5}
                  isMonthPickerVisible={true}
                  strings={DayPickerStrings}
                  contentAfter={<></>}
                  style={{ width: "100px" }}
                  onSelectDate={(data: any) =>
                    changeDateProp(data, "TransportDateTime")
                  }
                  value={
                    quote?.TransportDateTime
                      ? new Date(quote?.TransportDateTime)
                      : null
                  }
                  allowTextInput
                  formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
                />
              </div>
              <div className={styles.alignRight}>
                <div className={styles.label}>
                  <Label size="small">Szállítási mód</Label>
                </div>
                <SelectComponent
                  disabled={quote?.Status === 5}
                  options={
                    transportModes && (Object.values(transportModes) as any)
                  }
                  value={
                    (transportModes &&
                      quote?.TransportMode &&
                      transportModes[quote?.TransportMode]?.Name) ??
                    ""
                  }
                  action={editSelectTransport}
                />
                <div className={styles.label}>
                  <Label size="small">Fizetési mód</Label>
                </div>

                <SelectComponent
                  disabled={quote?.Status === 5}
                  noEmptyOption
                  options={
                    paymentMethods && (Object.values(paymentMethods) as any)
                  }
                  value={
                    paymentMethods && quote?.PaymentMethod
                      ? paymentMethods[quote?.PaymentMethod]?.Name
                      : ""
                  }
                  action={editSelectPaymentMethod}
                />
              </div>
            </div>
          </div>
        </div>
        <QuoteDetailTable
          isLoading={isLoading}
          quote={quote}
          setQuote={setQuote}
          selectDetail={selectDetail}
        />

        <div className={styles.alignRight}>
          <div style={{ display: "inline-block", width: "100px" }}>
            Részösszeg
          </div>
          <div
            style={{
              display: "inline-block",
              width: "250px",
              margin: "5px",
            }}
          >
            {currencies &&
              quote?.Currency &&
              quote &&
              formatNumberWithQuantityUnit(
                getQuoteNetValueSum(quote),
                currencies[quote?.Currency],
                currencies[quote?.Currency]?.NetValueDigits
              )}
          </div>
        </div>
        <div className={styles.alignRight}>
          <div style={{ display: "inline-block", width: "100px" }}>
            Kedvezmény
          </div>
          <div
            style={{
              display: "inline-block",
              width: "250px",
              margin: "5px",
            }}
          >
            <InputComponent
              disabled={quote?.Status === 5}
              value={quoteDiscount ?? quote?.DiscountPercent ?? 0}
              name={"DiscountPercent"}
              min={0}
              max={100}
              numeric
              onChange={updateQuoteDiscount}
              onFocusLeave={updateQuote}
            />
            {`%`}
          </div>
        </div>
        <div className={styles.alignRight}>
          <div style={{ display: "inline-block", width: "100px" }}>Áfa</div>
          <div
            style={{
              display: "inline-block",
              width: "250px",
              margin: "5px",
            }}
          >
            {currencies &&
              quote?.Currency &&
              vats &&
              quote &&
              formatNumberWithQuantityUnit(
                getQuoteVatValueSum(quote, vats),
                currencies[quote?.Currency],
                currencies[quote?.Currency]?.VatValueDigits
              )}
          </div>
        </div>
        <div className={styles.alignRight} style={{ margin: "10px" }}>
          <div
            style={{
              display: "inline-block",
              margin: 10,
              marginTop: 0,
              marginRight: 0,
            }}
          >
            {currencyRate && currencyRate > 1 && (
              <Fragment>
                Beállított árfolyam:
                <Button
                  disabled={quote?.Status === 5}
                  appearance="primary"
                  style={{
                    backgroundColor: "#adadad",
                    marginLeft: "5px",
                  }}
                  onClick={changeCurrencyRate}
                >
                  Módosítás
                </Button>
              </Fragment>
            )}
          </div>
          <div
            style={{
              display: "inline-block",
              margin: 10,
              marginTop: 0,
              fontWeight: 700,
            }}
          >
            {quote?.CurrencyRate &&
              quote?.CurrencyRate > 1 &&
              quote?.CurrencyRate}
          </div>
          <div
            style={{
              fontSize: "22px",
              display: "inline-block",
              margin: 10,
              marginTop: 0,
              marginLeft: "50px",
            }}
          >
            Összesen
          </div>
          <div
            style={{
              fontSize: "22px",
              fontWeight: 700,
              display: "inline-block",
              maxWidth: "20vw",
            }}
          >
            {quote
              ? getQuoteTotal(
                  quote,
                  paymentMethods && quote?.PaymentMethod
                    ? paymentMethods[quote?.PaymentMethod]
                    : undefined,
                  currencies && quote?.Currency
                    ? currencies[quote?.Currency]
                    : undefined,
                  vats
                )
              : 0}
          </div>
        </div>
        <div className={styles.alignRight} style={{ margin: "10px" }}>
          <div
            style={{
              display: "inline-block",
              margin: 10,
              marginTop: 0,
              marginRight: 0,
            }}
          >
            Nyelv:
          </div>
          <div
            style={{
              display: "inline-block",
              margin: 10,
              marginTop: 0,
            }}
          >
            <span style={{ marginRight: "5px" }}>{`Magyar /`}</span>
            <SelectComponent
              disabled={quote?.Status === 5}
              options={languages as any}
              value={
                languages?.find(
                  (x) => x.InternalCode === quote?.LanguageInternalCode
                )?.Name ?? ""
              }
              action={editSelectLanguage}
              noEmptyOption
            />
          </div>
        </div>
        <div
          style={{
            position: "relative",

            display: "flex",
          }}
          onClick={handleFlip}
        >
          <div
            style={{
              position: "absolute",
              bottom: "20px",
              left: "20px",
              display: "flex",
            }}
          >
            <span className={styles.comments}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            </span>
            <span
              style={{
                marginLeft: "5px",
                marginTop: "5px",
                cursor: "pointer",
              }}
            >
              Megjegyzések
            </span>
          </div>
        </div>
      </Fragment>

      <div>
        <Comments handleFlip={handleFlip} />
      </div>
    </CardFlip>
  );
};

export default QuoteForm;
