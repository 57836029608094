/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BisnodeQuickSearchResultDto } from '../models/BisnodeQuickSearchResultDto';
import type { BisnodeSearchResultDto } from '../models/BisnodeSearchResultDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BisnodeDataService {

    /**
     * @returns BisnodeSearchResultDto Success
     * @throws ApiError
     */
    public static getBisnodeSearch(): CancelablePromise<Array<BisnodeSearchResultDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bisnode/search',
        });
    }

    /**
     * @param s 
     * @returns BisnodeQuickSearchResultDto Success
     * @throws ApiError
     */
    public static getBisnodeQuicksearchall(
s: string,
): CancelablePromise<Array<BisnodeQuickSearchResultDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bisnode/quicksearchall/{s}',
            path: {
                's': s,
            },
        });
    }

    /**
     * @param s 
     * @returns BisnodeQuickSearchResultDto Success
     * @throws ApiError
     */
    public static getBisnodeQuicksearchact(
s: string,
): CancelablePromise<Array<BisnodeQuickSearchResultDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bisnode/quicksearchact/{s}',
            path: {
                's': s,
            },
        });
    }

}
