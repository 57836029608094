import { Fragment, useContext, useEffect, useState } from "react";
import {
  DeviceContext,
  MasterDataContext,
  QuoteContext,
} from "../utils/GlobalContexts";
import {
  calculatePercentage,
  getCompanyName,
  getMasterData,
} from "../utils/HelperFunctions";
import { TAB_TYPE, LIST_TYPE } from "../utils/Enums";
import DialogConfirm from "../components/dialog/DialogConfirm";
import Header from "../components/header/Header";
import PageHeader from "../components/page-header/PageHeader";
import ListContainer from "../components/lists/ListContainer";
import { useStylesMainPage } from "../styles/MainPageStyles";
import {
  CustomerDto,
  DocumentPrintService,
  MasterDataService,
  QuoteDetailDto,
  QuoteDto,
  QuoteHeadDto,
  VoucherService,
} from "../KulcsUzletApi";
import QuoteForm from "../components/forms/QuoteForm";
import DialogEditCustomer from "../components/dialog/DialogEditCustomer";
import DialogSetCurrency from "../components/dialog/DialogSetCurrency";
import axios from "axios";
import DialogEditDetail from "../components/dialog/DialogEditDetail";
import MobileBottomMenu from "../components/bottom-menu/MobileBottomMenu";
import MobileTabs from "../components/mobile-tabs/MobileTabs";
import QuoteFormMobile from "../components/forms/QuoteFormMobile";
import _ from "lodash";
import { useParams } from "react-router-dom";
import DialogSendEmail from "../components/dialog/DialogSendEmail";
import SelectComponent from "../components/select/SelectComponent";
import { quoteNames } from "../utils/GlobalConsts";

interface Props {}
export default function QuotePage({}: Props) {
  const { isMobile } = useContext(DeviceContext);
  const [companyName, setCompanyName] = useState("");
  const {
    setVats,
    setQuantityUnits,
    setProductCategories,
    setProjects,
    setCustomerCategories,
    setCurrencies,
    setPaymentMethods,
    setCountries,
    currencies,
    setTransportModes,
    setLanguages,
    setUserSettings,
  } = useContext(MasterDataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  const [openResultDialog, setOpenResultDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);
  const [dialogText, setDialogText] = useState<string | null>(null);
  const [currencyRate, setCurrencyRate] = useState<number | undefined>(1);
  const [unsavedCurrencyRate, setUnsavedCurrencyRate] = useState<
    number | undefined
  >(1);
  let { id } = useParams();
  const [activeTab, setActiveTab] = useState(
    id ? TAB_TYPE.INVOICE : TAB_TYPE.CUSTOMERS
  );
  const [currentListType, setCurrentListType] = useState(
    id ? LIST_TYPE.PRODUCT : LIST_TYPE.CUSTOMER
  );
  const styles = useStylesMainPage();
  const { quote, setQuote } = useContext(QuoteContext);
  const [newCustomerModal, setNewCustomerModal] = useState(false);
  const [openCurrencyRateModal, setOpenCurrencyRaterModal] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState<QuoteDetailDto | null>(
    null
  );
  const [shouldUpdateCurrency, setShouldUpdateCurrency] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  useEffect(() => {
    if (companyName === "") {
      getCompanyName(setCompanyName);
    }
  }, [companyName]);

  useEffect(() => {
    setIsLoading(true);
    getData().then(() => setIsLoading(false));
  }, []);

  const getHeadData = async (quoteid: string) => {
    try {
      const resHead: QuoteHeadDto = await VoucherService.getVoucherQuote(
        Number(quoteid)
      );
      return resHead;
    } catch (error) {
      console.log(error);
    }
  };

  const getDetailData = async (quoteid: string) => {
    try {
      let quoteDetails: QuoteDetailDto[] =
        await VoucherService.getVoucherQuotedetails(Number(quoteid));
      quoteDetails.map((d) => (d.UnitPrice = d.OriginalPrice));
      quoteDetails.map((d) => (d.QuoteId = null));
      quoteDetails.map((d) => (d.DiscountPercent = d.DiscountPercent ?? 0));
      return quoteDetails;
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerObj = async (customerid: number) => {
    try {
      const customer: CustomerDto =
        await MasterDataService.getMasterdataGetcustomerbyid(customerid);
      return customer;
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    const action = localStorage.getItem("action");
    let quoteId = undefined;
    if (action === "edit" || action === "copy") {
      setCurrentListType(LIST_TYPE.PRODUCT);
      setActiveTab(TAB_TYPE.INVOICE);
      quoteId = id ?? localStorage.getItem("id");
    } else localStorage.removeItem("id");

    await getMasterData(
      setQuantityUnits,
      setVats,
      setProductCategories,
      setProjects,
      setTransportModes,
      setCustomerCategories,
      setCurrencies,
      setPaymentMethods,
      setCountries,
      setLanguages,
      setUserSettings
    );

    if (quoteId) {
      const quoteHead = await getHeadData(quoteId);
      const quoteDetails = await getDetailData(quoteId);

      let customerobj: CustomerDto | undefined = undefined;
      if (quoteHead?.Customer)
        customerobj = await getCustomerObj(quoteHead?.Customer);
      let quoteDto = quoteHead as QuoteDto;
      quoteDto.Items = quoteDetails;
      quoteDto.CustomerObj = customerobj;

      if (action === "copy") {
        quoteDto.Id = undefined;
        let latestNumber = await VoucherService.getVoucherGetlatestquotenumber(
          true
        );
        quoteDto.VoucherNumber = `${(++latestNumber)
          .toString()
          .padStart(5, "0")}/${new Date().getFullYear()}`;

        quoteDto?.Items?.map(
          (x) => (x.Id = Math.floor(Math.random() * 10000000))
        );
      }
      setQuote(quoteDto);
      setCurrencyRate(quoteDto.CurrencyRate);
    } else {
      const comments = await VoucherService.getVoucherDefaultdocumentcomments();
      let latestNumber = await VoucherService.getVoucherGetlatestquotenumber(
        true
      );
      const exampleQuote: QuoteDto = {
        Id: undefined,
        VoucherNumber: `${(++latestNumber)
          .toString()
          .padStart(5, "0")}/${new Date().getFullYear()}`,
        PaymentMethod: 313,
        Currency: 245,
        CurrencyRate: 1,
        DiscountPercent: undefined,
        Items: [],
        VoucherDate: new Date().toString(),
        DeliveryDate: new Date().toString(),
        QuoteName: "Ajánlat",
        LanguageInternalCode: "HU",
        TopNotes: comments?.DefaultQuoteTopComment ?? "",
        BottomNotes: comments?.DefaultQuoteComment ?? "",
      };
      setCurrencyRate(1);
      setQuote(exampleQuote);
    }
  };

  useEffect(() => {
    if (quote?.Currency && shouldUpdateCurrency) {
      updateCurrency();
      setShouldUpdateCurrency(false);
    }
  }, [shouldUpdateCurrency]);

  useEffect(() => {
    if (currencyRate && quote?.Currency) {
      if (quote?.Id && firstRender) {
        setFirstRender(false);
        return;
      } else if (quote?.Items?.length && quote?.Items?.length > 0) {
        updateItemUnitPrices();
      } else {
        setQuote((prev: QuoteDto) => ({ ...prev, CurrencyRate: currencyRate }));
      }
    }
  }, [currencyRate]);

  const updateCurrency = async () => {
    const currencyname =
      currencies && quote?.Currency && currencies[quote.Currency]?.Name;

    if (currencyname && currencyname !== "HUF") {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/currencyRates/${currencyname}`
        );
        if (response.data) openSetCurrencyRateModal(response.data);
        else openSetCurrencyRateModal(1);
      } catch (e) {
        openSetCurrencyRateModal(1);
      }
    } else {
      setCurrencyRate(1);
    }
  };

  const updateItemUnitPrices = async () => {
    let items = _.cloneDeep(quote?.Items);
    let updateditems: QuoteDetailDto[] = [];
    if (items) {
      for (let i of items) {
        const unitprice = await getUnitPrice(i);
        i.UnitPrice = Number(unitprice);
        i.Currency = quote?.Currency;
        updateditems.push(i);
      }
    }
    const updatedQuote = {
      ...quote,
      Items: items,
      CurrencyRate: currencyRate,
    };
    setQuote(updatedQuote);
  };

  const getCalculatedUnitPrice = (detail: QuoteDetailDto) => {
    let discount = detail?.DiscountPercent ?? 0;
    let unitPriceMinusDiscount = calculatePercentage(
      detail?.UnitPrice,
      100 - discount
    );
    return unitPriceMinusDiscount;
  };

  const getUnitPrice = async (item: QuoteDetailDto) => {
    let currencyUnitPrice = null;
    let hufNetPrice = null;
    if (quote?.Currency !== 245) {
      const currencyUnitPriceDto = await getCurrencyUnitPriceDto(
        item.Product,
        quote?.Currency
      );

      if (currencyUnitPriceDto) {
        currencyUnitPrice = currencyUnitPriceDto.NetPrice;
      } else {
        const hufUnitPriceDto = await getHufUnitPrice(item.Product);

        if (hufUnitPriceDto) {
          hufNetPrice = hufUnitPriceDto.NetPrice;
        }
      }
    } else {
      const hufUnitPriceDto = await getHufUnitPrice(item.Product);

      if (hufUnitPriceDto) {
        hufNetPrice = hufUnitPriceDto.NetPrice;
      }
    }
    if (currencyUnitPrice) {
      return currencyUnitPrice;
    } else {
      return (hufNetPrice ? hufNetPrice : 0) / (currencyRate ?? 1) ?? 0;
    }
  };

  const getCurrencyUnitPriceDto = async (productid: any, currencyid: any) => {
    return await MasterDataService.getMasterdataCurrencyprice(
      productid,
      currencyid
    );
  };

  const getHufUnitPrice = async (productid: any) => {
    return await MasterDataService.getMasterdataHufprice(productid);
  };

  const openCustomerModal = () => {
    setNewCustomerModal(false);
    setOpenCustomerDialog(true);
  };

  const openResultModal = (text?: string) => {
    if (text) setDialogText(text);
    setOpenResultDialog(true);
  };

  const openNewCustomerModal = (text?: string) => {
    if (text) setDialogText(text);
    setNewCustomerModal(true);
    setOpenCustomerDialog(true);
  };

  const openSetCurrencyRateModal = (currencyValue?: number) => {
    if (currencyValue) setUnsavedCurrencyRate(currencyValue);
    setOpenCurrencyRaterModal(true);
  };

  const selectDetail = (detail: QuoteDetailDto) => {
    setSelectedDetail(detail);
    setOpenDetailDialog(true);
  };

  const handleSendEmailClick = () => {
    setOpenSendEmailDialog(true);
  };

  const handleSave = async (isSendEmail?: boolean) => {
    setIsSaveLoading(true);
    let originalQuoteDto = _.cloneDeep(quote);
    originalQuoteDto.VoucherType = 6;
    originalQuoteDto.DiscountPercent = originalQuoteDto.DiscountPercent ?? 0;

    if (originalQuoteDto.Items && originalQuoteDto.Items?.length > 0) {
      originalQuoteDto.Items = originalQuoteDto.Items?.map(
        (detail: QuoteDetailDto) => ({
          ...detail,
          UnitPrice: getCalculatedUnitPrice(detail),
          OriginalPrice: detail?.UnitPrice,
          DiscountPercent: detail?.DiscountPercent,
          Id: detail.QuoteId ? detail.Id : null,
          QuoteId: originalQuoteDto.Id ? detail.QuoteId : null,
        })
      );
    }

    if (originalQuoteDto.VoucherDate) {
      var voucherdate = new Date(originalQuoteDto.VoucherDate);
      originalQuoteDto.VoucherDate = new Date(
        voucherdate.getTime() - voucherdate.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }
    if (originalQuoteDto.DeliveryDate) {
      var deliverydate = new Date(originalQuoteDto.DeliveryDate);
      originalQuoteDto.DeliveryDate = new Date(
        deliverydate.getTime() - deliverydate.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }
    if (originalQuoteDto.TransportDateTime) {
      var transportdatetime = new Date(originalQuoteDto.TransportDateTime);
      originalQuoteDto.TransportDateTime = new Date(
        transportdatetime.getTime() -
          transportdatetime.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }

    let result: number | undefined | null = undefined;
    await VoucherService.postVoucherQuote(originalQuoteDto)
      .then((res) => {
        if (res.IsSuccess === false) {
          openResultModal(`Az árajánlat mentése nem sikerült: ${res.ErrorMsg}`);
          setIsSaveLoading(false);
          result = undefined;
        } else {
          setIsSaveLoading(false);
          result = res.QuoteDto?.Id;
        }
      })
      .catch((e) => {
        openResultModal(`Az árajánlat mentése nem sikerült: ${e?.message}`);
        console.log(e);
        setIsSaveLoading(false);
        result = undefined;
      });
    if (!isSendEmail && result) {
      openResultModal("Az árajánlat mentése sikeres volt.");
      window.location.replace(
        `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/quotes`
      );
    }
    return result;
  };

  const onChangeQuoteNameSelect = (e: any, data: any) => {
    setQuote((prev: QuoteDto) => ({ ...prev, QuoteName: data.optionText }));
  };

  const handleOpenPreview = async () => {
    setIsPreviewLoading(true);
    let originalQuoteDto = _.cloneDeep(quote);
    if (originalQuoteDto.VoucherDate) {
      const voucherdate = new Date(originalQuoteDto.VoucherDate);
      originalQuoteDto.VoucherDate = new Date(
        voucherdate.getTime() - voucherdate.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }
    if (originalQuoteDto.DeliveryDate) {
      const deliveryDate = new Date(originalQuoteDto.DeliveryDate);
      originalQuoteDto.DeliveryDate = new Date(
        deliveryDate.getTime() - deliveryDate.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }
    if (originalQuoteDto.TransportDateTime) {
      const transportDate = new Date(originalQuoteDto.TransportDateTime);
      originalQuoteDto.TransportDateTime = new Date(
        transportDate.getTime() - transportDate.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }

    originalQuoteDto.VoucherType = 6;

    originalQuoteDto.Items = originalQuoteDto.Items?.map(
      (detail: QuoteDetailDto) => ({
        ...detail,
        UnitPrice: getCalculatedUnitPrice(detail),
      })
    );

    await DocumentPrintService.postDocumentprintQuotepreview(originalQuoteDto)
      .then((res) => {
        if (res.IsSuccess === false) {
          openResultModal(
            `Az előnézet betöltése nem sikerült: ${res.ErrorMsg}`
          );
          setIsPreviewLoading(false);
        } else if (res.PreviewPages && res.PreviewPages.length > 0) {
          let contents: string[] = [];
          res.PreviewPages.forEach((p, index) =>
            contents.push(
              `<img src="data:image/png;base64,${p}" alt="${index}"/>`
            )
          );
          var tab = window.open(
            `${originalQuoteDto.VoucherNumber}_quote`,
            "_blank"
          );
          var html = `<html>
          <body>
            ${contents.join()}
          </body>
          </html>`;
          tab?.document.write(html);
          tab?.document.close();
          setIsPreviewLoading(false);
        }
      })
      .catch((e) => {
        openResultModal(`Az előnézet betöltése nem sikerült: ${e?.message}`);
        console.log(e);
        setIsPreviewLoading(false);
      });
  };

  return (
    <Fragment>
      {isMobile ? (
        <Fragment>
          <div className={styles.containerMobile}>
            <div className={styles.invoiceContainerMobile}>
              <div style={{ marginBottom: "10px" }}>
                <SelectComponent
                  disabled={quote?.Status === 5}
                  value={quote?.QuoteName ?? "Ajánlat"}
                  options={quoteNames as any}
                  action={onChangeQuoteNameSelect}
                  noEmptyOption
                />
              </div>
              <MobileTabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                needPartnerTab
              />
              {activeTab === TAB_TYPE.CUSTOMERS && (
                <ListContainer
                  setShouldUpdateCurrency={setShouldUpdateCurrency}
                  type={LIST_TYPE.CUSTOMER}
                  setActiveTab={setActiveTab}
                  openNewCustomerModal={openNewCustomerModal}
                />
              )}
              {activeTab === TAB_TYPE.PRODUCTS && (
                <ListContainer type={LIST_TYPE.PRODUCT} />
              )}
              {activeTab === TAB_TYPE.INVOICE && (
                <QuoteFormMobile
                  handleSave={handleSave}
                  copy={localStorage.getItem("action") === "copy"}
                  setActiveTab={setActiveTab}
                  openCustomerModal={openCustomerModal}
                  openResultModal={openResultModal}
                  selectDetail={selectDetail}
                />
              )}
              <MobileBottomMenu
                handleOpenQuotePreview={handleOpenPreview}
                handleSaveQuote={handleSave}
                handleSendEmailQuoteClick={handleSendEmailClick}
                openResultModal={openResultModal}
                activeTab={activeTab}
              />
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <Header companyName={companyName} />
          <div className={styles.container}>
            <PageHeader
              title=""
              content={
                <SelectComponent
                  disabled={quote?.Status === 5}
                  fullwidth
                  value={quote?.QuoteName ?? "Ajánlat"}
                  options={quoteNames as any}
                  action={onChangeQuoteNameSelect}
                  noEmptyOption
                />
              }
            />
            <div className={styles.innerContainer} style={{ marginBottom: 0 }}>
              <div className={styles.containerLeft}>
                <ListContainer
                  setShouldUpdateCurrency={setShouldUpdateCurrency}
                  type={currentListType}
                  setType={setCurrentListType}
                  openNewCustomerModal={openNewCustomerModal}
                />
              </div>
              <div className={styles.containerRight}>
                <QuoteForm
                  isPreviewLoading={isPreviewLoading}
                  handleOpenPreview={handleOpenPreview}
                  isLoading={isLoading}
                  isSaveLoading={isSaveLoading}
                  handleSave={handleSave}
                  handleSendEmailClick={handleSendEmailClick}
                  copy={localStorage.getItem("action") === "copy"}
                  currencyRate={currencyRate}
                  openCustomerModal={openCustomerModal}
                  openResultModal={openResultModal}
                  updateCurrencyRate={updateCurrency}
                  selectDetail={selectDetail}
                  setShouldUpdateCurrency={setShouldUpdateCurrency}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
      <DialogConfirm
        okBtnText="Ok"
        closeBtnText="Bezár"
        message={dialogText ?? ""}
        title=""
        open={openResultDialog}
        setOpen={setOpenResultDialog}
      />
      <DialogEditCustomer
        setShouldUpdateCurrency={setShouldUpdateCurrency}
        okBtnText="Mentés"
        closeBtnText="Mégse"
        message={""}
        title={newCustomerModal ? "Új partner" : "Partner szerkesztése"}
        open={openCustomerDialog}
        setOpen={setOpenCustomerDialog}
        customerObj={
          newCustomerModal ? ({} as CustomerDto) : quote?.CustomerObj
        }
      />
      <DialogEditDetail
        okBtnText="Mentés"
        closeBtnText="Mégse"
        message={""}
        title="Tétel"
        open={openDetailDialog}
        setOpen={setOpenDetailDialog}
        detail={selectedDetail}
      />
      <DialogSetCurrency
        currencyRate={unsavedCurrencyRate}
        setCurrencyRate={setCurrencyRate}
        okBtnText="Mentés"
        closeBtnText={undefined}
        title="Árfolyam beállítása"
        message={
          "Ez az árfolyam lesz alkalmazva azoknál a termékeknél, amelyeknél a kiválaszott valutában nem lett megadva egyedi ár."
        }
        open={openCurrencyRateModal}
        setOpen={setOpenCurrencyRaterModal}
      />
      <DialogSendEmail
        openResultModal={openResultModal}
        handleSaveQuote={handleSave}
        title={"Bizonylat küldése e-mailben"}
        message={""}
        open={openSendEmailDialog}
        setOpen={setOpenSendEmailDialog}
        okBtnText={"Küldés"}
        closeBtnText="Mégse"
      />
    </Fragment>
  );
}
