import { Button, Label, Tooltip } from "@fluentui/react-components";
import { ListContainerProps } from "../../interfaces/ListContainerProps";
import { useStylesListContainer as useStylesListContainer } from "../../styles/ListContainerStyle";
import {
  changeFiltersObjPagination,
  getCategoryColor,
  getCustomers,
  getProducts,
  getRoundedNumber,
  getStockBalance,
  getvat,
  trimLongString,
  updateFiltersObj,
} from "../../utils/HelperFunctions";
import InputComponent from "../input/InputComponent";
import SelectComponent from "../select/SelectComponent";
import { defaultFilterObj } from "../../utils/GlobalConsts";
import { useContext, useEffect, useState } from "react";
import {
  DeviceContext,
  FilterContext,
  MasterDataContext,
  ModifierContext,
  QuoteContext,
  RefreshContext,
} from "../../utils/GlobalContexts";
import {
  CategoryDto,
  CurrencyDto,
  CustomerDto,
  MasterDataService,
  ModifierInvoiceDetailDto,
  ProductDto,
  QuoteDetailDto,
  QuoteDto,
} from "../../KulcsUzletApi";
import _ from "lodash";
import { FiltersObject } from "../../models/FilterObj";
import { COLOR, LIST_TYPE, TAB_TYPE } from "../../utils/Enums";

const ListContainer: React.FC<ListContainerProps> = ({
  type,
  setType,
  setActiveTab,
  openNewCustomerModal,
  setShouldUpdateCurrency,
}) => {
  const [searchphrase, setSearchphrase] = useState("");
  const [selectedProductCategory, setSelectedProductCategory] =
    useState<CategoryDto | null>(null);
  const [selectedCustomerCategory, setSelectedCustomerCategory] =
    useState<CategoryDto | null>(null);
  const { filtersObj, setFiltersObj } = useContext(FilterContext);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const styles = useStylesListContainer();
  const {
    customers,
    setCustomers,
    customerCategories,
    products,
    setProducts,
    productCategories,
    quantityUnits,
    vats,
  } = useContext(MasterDataContext);
  const { modifier, setModifier } = useContext(ModifierContext);
  const { quote, setQuote } = useContext(QuoteContext);
  const { isMobile } = useContext(DeviceContext);
  const [highlightedRows, setHighlightedRows] = useState<number[]>([]);
  const { currencies, userSettings } = useContext(MasterDataContext);
  const { refresh, setRefresh } = useContext(RefreshContext);

  useEffect(() => {
    setSearchphrase("");
    setCurrentPage(1);
    setSelectedCustomerCategory(null);
    setSelectedProductCategory(null);

    setFiltersObj(_.cloneDeep(defaultFilterObj) as FiltersObject);
  }, [type]);

  useEffect(() => {
    if (type === LIST_TYPE.PRODUCT)
      getProducts(filtersObj, setProducts, setPageCount);
    else getCustomers(filtersObj, setCustomers, setPageCount);
  }, [filtersObj]);

  useEffect(() => {
    if (refresh) {
      getCustomers(filtersObj, setCustomers, setPageCount);
      updateCustomer();
      setRefresh(false);
    }
  }, [refresh]);

  const getCurrencyUnitPriceDto = async (productid: any, currencyid: any) => {
    return await MasterDataService.getMasterdataCurrencyprice(
      productid,
      currencyid
    );
  };

  const updateCustomer = async () => {
    if (quote?.CustomerObj?.Id) {
      const freshlyAquiredCustomer =
        await MasterDataService.getMasterdataGetcustomerbyid(
          quote?.CustomerObj?.Id
        );
      if (freshlyAquiredCustomer) {
        setQuote((prev: QuoteDto) => ({
          ...prev,
          CustomerObj: freshlyAquiredCustomer,
          Currency: freshlyAquiredCustomer.DefaultCurrency ?? prev.Currency,
          PaymentMethod:
            freshlyAquiredCustomer.PaymentMethod ?? prev.PaymentMethod,
          // DeliveryDate: freshlyAquiredCustomer.PaymentMethodDelayDay
          //   ? getCustomExtendedDate(
          //       freshlyAquiredCustomer.PaymentMethodDelayDay
          //     )
          //   : prev.DeliveryDate,
        }));
      }
    }
  };

  const searchProductsCategory = (ev: any, data: any) => {
    setSelectedProductCategory(data.optionValue);
    const category =
      data.optionValue === "Összes"
        ? null
        : (data.optionValue as CategoryDto)?.Id ?? "";
    updateFiltersObj(category, searchphrase, setFiltersObj, type);
  };

  const searchCustomersCategory = (ev: any, data: any) => {
    setSelectedCustomerCategory(data.optionValue);
    const category =
      data.optionValue === "Összes"
        ? null
        : (data.optionValue as CategoryDto)?.Id ?? "";
    updateFiltersObj(category, searchphrase, setFiltersObj, type);
  };

  const searchProducts = (e: any) => {
    setSearchphrase(e.target.value);
    setCurrentPage(1);
    updateFiltersObj(
      selectedProductCategory?.Id,
      e.target.value,
      setFiltersObj,
      type,
      true
    );
  };

  const searchCustomers = (e: any) => {
    setSearchphrase(e.target.value);
    setCurrentPage(1);
    updateFiltersObj(
      selectedCustomerCategory?.Id,
      e.target.value,
      setFiltersObj,
      type,
      true
    );
  };

  const highlight = (id: number | null | undefined) => {
    if (!id) return;
    setHighlightedRows((prev) => [...prev, id]);
    setTimeout(() => {
      setHighlightedRows((prev) => prev.filter((item) => item !== id));
    }, 1000);
  };

  const addItemToVoucherDetails = async (product: ProductDto) => {
    if (!modifier && !quote) return;

    if (isMobile) highlight(product.Id);

    addItem(product);
  };

  const addItem = async (product: ProductDto) => {
    let updatedItems: any[] = [];
    if (quote) updatedItems = [...(quote.Items ?? [])];
    else if (modifier) updatedItems = [...(modifier.DetailList ?? [])];

    let existingItemIndex = -1;
    if (!(userSettings?.NewVoucherItemMode === 0))
      existingItemIndex =
        updatedItems?.findIndex(
          (x) =>
            ((x.ProductId === product.Id &&
              modifier &&
              (x.DiscountPercent === 0 ||
                x.DiscountPercent === modifier?.DiscountPercent ||
                x.DiscountPercent === modifier?.CustomerDiscountPercent)) ||
              (x.Product === product.Id &&
                quote &&
                (x.DiscountPercent === 0 ||
                  x.DiscountPercent === quote?.DiscountPercent ||
                  x.DiscountPercent ===
                    quote?.CustomerObj?.DiscountPercent))) &&
            Number(x.Quantity ?? 0) > 0
        ) ?? -1;

    if (existingItemIndex > -1) {
      updatedItems[existingItemIndex] = {
        ...updatedItems[existingItemIndex],
        Quantity: Number(updatedItems[existingItemIndex]?.Quantity ?? 0) + 1,
      };
    } else {
      let currencyUnitPrice = null;
      let currency: CurrencyDto | null | undefined = undefined;
      if (modifier) currency = modifier.Currency;
      else if (quote && currencies && quote?.Currency)
        currency = currencies[quote.Currency];

      if (product.Id && currency?.Id && currency?.Name !== "HUF") {
        const currencyUnitPriceDto = await getCurrencyUnitPriceDto(
          product.Id,
          currency?.Id
        );
        if (currencyUnitPriceDto)
          currencyUnitPrice = currencyUnitPriceDto.NetPrice;
      }

      const newItem = getNewDetailItem(
        product,
        currencyUnitPrice,
        currency,
        quote?.CurrencyRate ?? 1
      );

      updatedItems = quote
        ? [...(quote.Items ?? []), newItem]
        : modifier
        ? [...(modifier.DetailList ?? []), newItem]
        : [];
    }

    if (quote) {
      const updatedQuote = {
        ...quote,
        Items: updatedItems,
      };

      setQuote(updatedQuote);
    } else if (modifier) {
      const updatedInvoice = {
        ...modifier,
        DetailList: updatedItems,
      };

      setModifier(updatedInvoice);
    }
  };

  const getNewDetailItem = (
    product: ProductDto,
    currencyUnitPrice: number | null | undefined,
    currency: CurrencyDto | null | undefined,
    currencyRate: number
  ) => {
    if (modifier) {
      return {
        DiscountPercent: undefined,
        Id: Math.floor(Math.random() * 10000000),
        ProductId: product.Id,
        ProductNameDisplay: product.Name,
        UnitPrice: currencyUnitPrice ?? product.UnitPrice ?? 0,
        QuantityName:
          quantityUnits && product.QuantityUnit
            ? quantityUnits[product.QuantityUnit]?.Name
            : "",
        VatPercent: getvat(vats, product.Vat),
        Quantity: 1,
        IsNewItem: true,
        CustomerStockOutDetailId: null,
      } as ModifierInvoiceDetailDto;
    } else if (quote)
      return {
        DiscountPercent:
          quote?.DiscountPercent ??
          quote?.CustomerObj?.DiscountPercent ??
          undefined,
        Id: Math.floor(Math.random() * 10000000),
        Product: product.Id,
        ProductNameDisplay: product.Name,
        UnitPrice: currencyUnitPrice ?? (product.UnitPrice ?? 0) / currencyRate,
        QuantityUnit:
          quantityUnits && product.QuantityUnit
            ? quantityUnits[product.QuantityUnit]?.Id
            : undefined,
        Currency: quote?.Currency,
        Vat: product.Vat,
        Quantity: 1,
        CustomerStockOutDetailId: null,
        OriginalPrice: product.UnitPrice,
        DetailComment: product.Comment,
      } as QuoteDetailDto;
  };

  const changeCustomer = async (customer: CustomerDto) => {
    if (!quote) return;
    if (isMobile) highlight(customer.Id);

    const updatedQuote = {
      ...quote,
      Customer: customer.Id,
      CustomerNameDisplay: customer.Name,
      CustomerObj: customer,
      Currency: customer.DefaultCurrency ?? quote.Currency,
      PaymentMethod: customer.PaymentMethod ?? quote.PaymentMethod,
    };
    await setQuote(updatedQuote);

    if (
      quote.Currency !== updatedQuote.Currency ||
      updatedQuote.Currency !== 245
    )
      setShouldUpdateCurrency && setShouldUpdateCurrency(true);

    if (setType)
      setTimeout(() => {
        setType(LIST_TYPE.PRODUCT);
      }, 1000);
    if (setActiveTab) {
      setTimeout(() => {
        setActiveTab(TAB_TYPE.PRODUCTS);
      }, 1000);
    }
  };

  const changeCurrentPage = (e: any) => {
    let value = e.target.value;
    if (value > pageCount) {
      value = pageCount;
      setCurrentPage(pageCount);
    } else if (value < 1) {
      value = 1;
      setCurrentPage(1);
    }

    setFiltersObj((prev: FiltersObject) => ({
      ...prev,
      Skip: (value - 1) * prev.Take,
    }));
  };

  const changeCurrentPageLocal = (e: any) => {
    setCurrentPage(e.target.value);
  };

  const changeCurrency = async (ev: any, data: any) => {
    setQuote((prev: QuoteDto) => ({
      ...prev,
      Currency: (data.optionValue as CurrencyDto)?.Id ?? undefined,
    }));
  };

  function getPrice(item: ProductDto) {
    return getRoundedNumber(
      4,
      userSettings?.ListValueMode === 0 ? item.UnitPrice : item.GrossUnitPrice
    );
  }

  return (
    <div>
      {!isMobile && (
        <div className={styles.listName}>
          {setType && (
            <span
              style={{
                cursor: "pointer",
                borderTop:
                  type === LIST_TYPE.CUSTOMER
                    ? "4px solid #3f76bb"
                    : "4px solid transparent",
                fontSize: "15px",
                fontFamily: "Oxygen",
                padding: 5,
              }}
              onClick={() => {
                setType(LIST_TYPE.CUSTOMER);
              }}
            >
              PARTNEREK
            </span>
          )}
          {setType && (
            <span
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                fontSize: "13px",
              }}
            >
              |
            </span>
          )}
          <span
            style={{
              marginRight: 20,
              cursor: "pointer",
              borderTop:
                type === LIST_TYPE.PRODUCT
                  ? "4px solid #3f76bb"
                  : "4px solid transparent",
              fontSize: "15px",
              fontFamily: "Oxygen",
              padding: 5,
            }}
            onClick={() => {
              setType && setType(LIST_TYPE.PRODUCT);
            }}
          >
            TERMÉKEK
          </span>
        </div>
      )}
      {isMobile && type === LIST_TYPE.CUSTOMER && (
        <div
          style={{
            backgroundColor: "rgba(221, 232, 241, 0.75)",
            borderBottom: "1px solid rgba(149, 183, 211, 0.75)",
            display: "flex",
            padding: "5px",
          }}
        >
          <Label
            style={{
              lineHeight: "30px",
              color: "#666666",
              textAlign: "center",
              width: "100%",
            }}
          >
            Pénznem
          </Label>
          <span style={{ textAlign: "right", width: "100%" }}>
            <SelectComponent
              noEmptyOption
              value={
                (currencies &&
                  quote?.Currency &&
                  currencies[quote?.Currency]?.DisplayName) ??
                ""
              }
              options={currencies && (Object.values(currencies) as any)}
              action={changeCurrency}
            />
          </span>
        </div>
      )}
      <div>
        <InputComponent
          value={searchphrase}
          name=""
          onChange={
            type === LIST_TYPE.CUSTOMER ? searchCustomers : searchProducts
          }
          fullwidth
          // small
          placeholder={
            type === LIST_TYPE.CUSTOMER
              ? "Keresés névre, címre, kódra, adószámra"
              : "Keresés névre vagy termékkódra"
          }
        />
        <div style={{ margin: 5 }}></div>
        <SelectComponent
          autoWidth
          hasAllOption
          options={
            type === LIST_TYPE.CUSTOMER
              ? customerCategories && (Object.values(customerCategories) as any)
              : productCategories && (Object.values(productCategories) as any)
          }
          action={
            type === LIST_TYPE.CUSTOMER
              ? searchCustomersCategory
              : searchProductsCategory
          }
          label={
            type === LIST_TYPE.CUSTOMER ? "Partnerkategória" : "Termékkategória"
          }
          value={
            type === LIST_TYPE.CUSTOMER
              ? selectedCustomerCategory?.Name
              : selectedProductCategory?.Name
          }
        />
      </div>
      <div className={isMobile ? styles.mobileContainer : styles.container}>
        {type === LIST_TYPE.CUSTOMER && (
          <div className={styles.article} onClick={openNewCustomerModal}>
            <div
              className={styles.image}
              style={{
                backgroundColor: COLOR.BIZ_DARK_ORANGE,
              }}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  height="40px"
                  viewBox="0 0 640 512"
                >
                  <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                </svg>
              </div>
            </div>
            <div className={styles.client} style={{ overflow: "hidden" }}>
              <div className={styles.newClientText}>
                <h2 className={styles.newClientButton}>
                  Új partner hozzáadása
                </h2>
              </div>
            </div>
          </div>
        )}

        {((products && type === LIST_TYPE.PRODUCT) ||
          (customers && type === LIST_TYPE.CUSTOMER)) &&
          Object.values(
            (type === LIST_TYPE.PRODUCT ? products : customers) as any
          )?.map((item: any, index) => {
            let stockBalance = modifier
              ? getStockBalance(item, modifier?.DetailList)
              : getStockBalance(item, quote?.Items);

            return (
              <div
                key={index}
                style={
                  isMobile && highlightedRows?.includes(item.Id ?? -1)
                    ? {
                        backgroundColor: COLOR.BIZ_DARK_ORANGE,
                        marginBottom: "5px",
                      }
                    : isMobile
                    ? { marginBottom: "5px" }
                    : {}
                }
              >
                <div
                  style={
                    isMobile && highlightedRows?.includes(item.Id ?? -1)
                      ? {
                          position: "absolute",
                          color: "white",
                          fontSize: "25px",
                          textAlign: "center",
                          width: "95%",
                          marginTop: "30px",
                        }
                      : {
                          display: "none",
                        }
                  }
                >
                  Hozzáadva
                </div>
                <div
                  style={
                    isMobile && highlightedRows?.includes(item.Id ?? -1)
                      ? { border: "4px solid #3f76bb", opacity: 0.4 }
                      : {}
                  }
                  className={isMobile ? styles.mobileArticle : styles.article}
                  onClick={() =>
                    quote?.Status === 5
                      ? {}
                      : type === LIST_TYPE.PRODUCT
                      ? addItemToVoucherDetails(item)
                      : changeCustomer(item)
                  }
                >
                  <div
                    className={styles.image}
                    style={{
                      backgroundColor: getCategoryColor(
                        type === LIST_TYPE.PRODUCT
                          ? productCategories && item?.Category1
                            ? productCategories[item?.Category1]?.Color ?? -1
                            : -1
                          : customerCategories && item?.Category1
                          ? customerCategories[item?.Category1]?.Color ?? -1
                          : -1
                      ),
                    }}
                  >
                    <div>
                      {type === LIST_TYPE.CUSTOMER ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="white"
                          height="40px"
                          viewBox="0 0 448 512"
                        >
                          <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="40px"
                          fill="white"
                          viewBox="0 0 512 512"
                        >
                          <path d="M296 32h192c13.255 0 24 10.745 24 24v160c0 13.255-10.745 24-24 24H296c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24zm-80 0H24C10.745 32 0 42.745 0 56v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zM0 296v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm296 184h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H296c-13.255 0-24 10.745-24 24v160c0 13.255 10.745 24 24 24z" />
                        </svg>
                      )}
                    </div>
                    {/* TERMÉK KÓD */}
                    <div className={styles.clientcode}>{item.Code}</div>
                  </div>
                  <div className={styles.client} style={{ overflow: "hidden" }}>
                    {/* TERMÉK NÉV */}
                    <div className={styles.name}>
                      <Tooltip content={item.Name ?? ""} relationship={"label"}>
                        <span>{trimLongString(item.Name, 40)}</span>
                      </Tooltip>
                    </div>
                    {type === LIST_TYPE.CUSTOMER && (
                      <div className={styles.name}>{item.Phone}</div>
                    )}
                    {/* STOCK BALANCE + MENNYISÉGI EGYSÉG */}
                    {type === LIST_TYPE.PRODUCT && (
                      <div className={styles.bottomLine}>
                        <div
                          className={styles.quantityunit}
                          style={{
                            color:
                              item.HasStockBalance === 1 &&
                              item.StockBalance &&
                              stockBalance <= 0
                                ? "red"
                                : "black",
                          }}
                        >
                          {`${
                            item.HasStockBalance === 1 && item.StockBalance
                              ? stockBalance
                              : ""
                          } ${
                            quantityUnits && item.QuantityUnit
                              ? quantityUnits[item.QuantityUnit]?.Name
                              : ""
                          }`}
                        </div>
                        {/* EGYSÉGÁR */}
                        <div className={styles.price}>
                          {`${getPrice(item)} Ft`}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div style={{ marginTop: "10px" }}>
        <InputComponent
          value={currentPage}
          name={""}
          minwidth
          numeric
          onChange={changeCurrentPageLocal}
          onFocusLeave={changeCurrentPage}
        />
        <span style={{ margin: "auto", marginLeft: "5px" }}>{` / ${
          pageCount ?? 0
        }`}</span>
        <div style={{ float: "right", margin: "auto" }}>
          <Button
            size="small"
            style={{
              backgroundColor: "#ccc",
              border: "1px solid #545454",
              borderRadius: 0,
              color: "#545454",
              marginRight: "5px",
            }}
            appearance="primary"
            disabled={filtersObj.Skip === 0}
            onClick={() =>
              changeFiltersObjPagination(
                "prev",
                setFiltersObj,
                setCurrentPage,
                currentPage
              )
            }
          >
            Előző
          </Button>
          <Button
            size="small"
            style={{
              backgroundColor: "#ccc",
              border: "1px solid #545454",
              borderRadius: 0,
              color: "#545454",
              marginRight: "5px",
            }}
            appearance="primary"
            disabled={filtersObj.Skip / filtersObj.Take + 1 === pageCount}
            onClick={() =>
              changeFiltersObjPagination(
                "next",
                setFiltersObj,
                setCurrentPage,
                currentPage
              )
            }
          >
            Következő
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ListContainer;
