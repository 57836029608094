import CardFlip from "../card-flip/CardFlip";
import Comments from "../comments/Comments";
import InputComponent from "../input/InputComponent";
import ModifierDetailTable from "../detail-table/VoucherDetailTable";
import { Button, Label } from "@fluentui/react-components";
import { COLOR } from "../../utils/Enums";
import { InvoiceFormProps } from "../../interfaces/InvoiceFormProps";
import { useStylesInvoiceForm } from "../../styles/InvoiceFormStyles";
import { formatNumberWithQuantityUnit } from "../../utils/HelperFunctions";
import {
  MasterDataContext,
  ModifierContext,
  QuoteContext,
} from "../../utils/GlobalContexts";
import {
  ModifierInvoiceDetailDto,
  ModifierInvoiceDto,
  ModifierInvoiceService,
  ProjectDto,
} from "../../KulcsUzletApi";
import _ from "lodash";
import { ClipLoader } from "react-spinners";
import { Fragment, useContext, useState } from "react";
import SelectComponent from "../select/SelectComponent";
import { DatePicker, DayOfWeek } from "@fluentui/react-datepicker-compat";
import { DayPickerStrings } from "../../utils/GlobalConsts";
import {
  getModifierNetValueSum,
  getModifierVatValueSum,
  getModifierTotal,
} from "../../utils/ModifierFunctions";

const ModifierForm: React.FC<InvoiceFormProps> = ({
  openModal,
  originalItemIds,
}) => {
  const styles = useStylesInvoiceForm();
  const [isFlipped, setIsFlipped] = useState(false);
  const handleFlip = () => setIsFlipped(!isFlipped);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isSendSaveLoading, setIsSendSaveLoading] = useState(false);
  const { modifier, setModifier } = useContext(ModifierContext);
  const { projects } = useContext(MasterDataContext);

  const handleSaveModifier = async (send: boolean) => {
    send ? setIsSendSaveLoading(true) : setIsSaveLoading(true);
    let originalModifierInvoiceDto = _.cloneDeep(modifier);
    originalModifierInvoiceDto.DetailList =
      originalModifierInvoiceDto.DetailList?.map(
        (detail: ModifierInvoiceDetailDto) => ({
          ...detail,
          Id: detail.IsNewItem ? null : detail.Id,
        })
      );

    if (originalModifierInvoiceDto.PaymentDate) {
      var payment = originalModifierInvoiceDto.PaymentDate;
      originalModifierInvoiceDto.PaymentDate = new Date(
        payment.getTime() - payment.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }

    originalModifierInvoiceDto.SendModifierAfterCreate = send;

    await ModifierInvoiceService.postModifierModify(originalModifierInvoiceDto)
      .then((res) => {
        if (res.IsSuccess === false) {
          openModal(`A helyesbítő mentése nem sikerült: ${res.ErrorMsg}`);
          send ? setIsSendSaveLoading(false) : setIsSaveLoading(false);
        } else {
          openModal("A helyesbítő mentése sikeres volt.");
          send ? setIsSendSaveLoading(false) : setIsSaveLoading(false);
          window.location.replace(
            `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/invoices`
          );
        }
      })
      .catch((e) => {
        openModal(`A helyesbítő mentése nem sikerült: ${e?.message}`);
        console.log(e);
        send ? setIsSendSaveLoading(false) : setIsSaveLoading(false);
      });
  };

  const handleOpenPreview = async () => {
    setIsPreviewLoading(true);
    let originalModifierInvoiceDto = _.cloneDeep(modifier);
    originalModifierInvoiceDto.DetailList =
      originalModifierInvoiceDto.DetailList?.map(
        (detail: ModifierInvoiceDetailDto) => ({
          ...detail,
          Id: detail.IsNewItem ? null : detail.Id,
        })
      );

    await ModifierInvoiceService.postModifierPreview(originalModifierInvoiceDto)
      .then((res) => {
        if (res.IsSuccess === false) {
          openModal(`Az előnézet betöltése nem sikerült: ${res.ErrorMsg}`);
          setIsPreviewLoading(false);
        } else if (res.PreviewPages && res.PreviewPages.length > 0) {
          let contents: string[] = [];
          res.PreviewPages.forEach((p, index) =>
            contents.push(
              `<img src="data:image/png;base64,${p}" alt="${index}"/>`
            )
          );
          var tab = window.open(
            `${originalModifierInvoiceDto.InvoiceNumber}_modifier`,
            "_blank"
          );
          var html = `<html>
          <body>
            ${contents.join()}
          </body>
          </html>`;
          tab?.document.write(html);
          tab?.document.close();
          setIsPreviewLoading(false);
        }
      })
      .catch((e) => {
        openModal(`Az előnézet betöltése nem sikerült: ${e?.message}`);
        console.log(e);
        setIsPreviewLoading(false);
      });
  };

  const changeProject = (ev: any, data: any) => {
    const project =
      data.optionValue === "Üres..." ? null : (data.optionValue as ProjectDto);
    setModifier((prev: ModifierInvoiceDto) => ({
      ...prev,
      Project: project,
    }));
  };

  const changeDateProp = (date: Date | null | undefined, property: string) =>
    setModifier((prev: ModifierInvoiceDto) => ({
      ...prev,
      [property]: date,
    }));

  return (
    <CardFlip isFlipped={isFlipped}>
      <Fragment>
        <div className={styles.floatRight} style={{ display: "block" }}>
          <div className={styles.alignRight}>
            <Button
              disabled={isPreviewLoading}
              className={styles.button}
              style={{ backgroundColor: COLOR.BIZ_PURPLE }}
              appearance="primary"
              onClick={handleOpenPreview}
            >
              {isPreviewLoading ? (
                <ClipLoader size={16} color="white" />
              ) : (
                "ELŐNÉZET"
              )}
            </Button>

            {modifier?.IsEInvoice && (
              <Button
                disabled={isSaveLoading}
                className={styles.button}
                style={{ backgroundColor: COLOR.BIZ_DARK_ORANGE }}
                appearance="primary"
                onClick={() => handleSaveModifier(true)}
              >
                {isSendSaveLoading ? (
                  <ClipLoader size={16} color="white" />
                ) : (
                  "VÉGLEGESÍTÉS ÉS KÜLDÉS"
                )}
              </Button>
            )}
            <Button
              disabled={isSaveLoading}
              className={styles.button}
              style={{ backgroundColor: COLOR.BIZ_DARK_ORANGE }}
              appearance="primary"
              onClick={() => handleSaveModifier(false)}
            >
              {isSaveLoading ? (
                <ClipLoader size={16} color="white" />
              ) : (
                "VÉGLEGESÍTÉS"
              )}
            </Button>
          </div>

          <div
            style={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className={styles.client}
              style={{ width: "48%", boxSizing: "border-box" }}
            >
              <div
                style={{
                  display: "inline-flex",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="42px"
                  // width="32x"
                  fill={COLOR.BIZ_DARK_ORANGE}
                  viewBox="0 0 448 380"
                >
                  <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                </svg>

                <div
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <h3 className={styles.clientInfo}>
                    {modifier?.CustomerName}
                  </h3>
                  <div className={styles.clientInfo}>
                    {modifier?.CustomerAddress}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.alignRight}>
                <h2 className={styles.vouchernumber}>
                  {modifier?.InvoiceNumber}
                </h2>
              </div>
              <div className={""}></div>
              <div className={styles.alignRight}>
                <div className={styles.label}>
                  <Label size="small">Projekt</Label>
                </div>
                <SelectComponent
                  defaultValue={modifier?.Project?.Name}
                  options={projects && (Object.values(projects) as any)}
                  action={changeProject}
                />
                <div className={styles.label}>
                  <Label size="small">Pénznem</Label>
                </div>
                <InputComponent
                  value={modifier?.Currency?.DisplayName ?? ""}
                  name=""
                  disabled
                />
              </div>
              <div className={styles.alignRight}>
                <div className={styles.label}>
                  <Label size="small">Kelte</Label>
                </div>
                <InputComponent
                  value={new Date().toLocaleDateString()}
                  name=""
                  disabled
                />
                <div className={styles.label}>
                  <Label size="small">Fizetési határidő</Label>
                </div>
                <DatePicker
                  firstDayOfWeek={DayOfWeek.Monday}
                  contentAfter={<></>}
                  style={{ width: "100px" }}
                  disabled={modifier?.PaymentMethod?.ImmediatePay === 1}
                  onSelectDate={(data: any) =>
                    changeDateProp(data, "PaymentDate")
                  }
                  value={modifier?.PaymentDate ?? new Date()}
                  allowTextInput
                  isMonthPickerVisible={true}
                  strings={DayPickerStrings}
                  formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
                />
              </div>
              <div className={styles.alignRight}>
                <div className={styles.label}>
                  <Label size="small">Teljesítés</Label>
                </div>
                <DatePicker
                  firstDayOfWeek={DayOfWeek.Monday}
                  disabled
                  isMonthPickerVisible={true}
                  strings={DayPickerStrings}
                  contentAfter={<></>}
                  style={{ width: "100px", background: "white" }}
                  onSelectDate={(data: any) =>
                    changeDateProp(data, "FulfillmentDate")
                  }
                  value={modifier?.FulfillmentDate ?? new Date()}
                  allowTextInput
                  formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
                />
                <div className={styles.label}>
                  <Label size="small">Fizetési mód</Label>
                </div>
                <InputComponent
                  value={modifier?.PaymentMethod?.Name ?? ""}
                  name=""
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <ModifierDetailTable originalItemIds={originalItemIds ?? []} />

        <div className={styles.alignRight}>
          <div style={{ display: "inline-block", width: "100px" }}>
            Részösszeg
          </div>
          <div
            style={{
              display: "inline-block",
              width: "250px",
              margin: "5px",
            }}
          >
            {modifier &&
              formatNumberWithQuantityUnit(
                getModifierNetValueSum(modifier),
                modifier.Currency,
                modifier.Currency?.NetValueDigits
              )}
          </div>
        </div>
        <div className={styles.alignRight}>
          <div style={{ display: "inline-block", width: "100px" }}>
            Kedvezmény
          </div>
          <div
            style={{
              display: "inline-block",
              width: "250px",
              margin: "5px",
            }}
          >
            {`${modifier?.DiscountPercent} %`}
          </div>
        </div>
        <div className={styles.alignRight}>
          <div style={{ display: "inline-block", width: "100px" }}>Áfa</div>
          <div
            style={{
              display: "inline-block",
              width: "250px",
              margin: "5px",
            }}
          >
            {modifier &&
              formatNumberWithQuantityUnit(
                getModifierVatValueSum(modifier),
                modifier.Currency,
                modifier.Currency?.GrossValueDigits
              )}
          </div>
        </div>

        <div className={styles.alignRight} style={{ margin: "10px" }}>
          <div
            style={{
              display: "inline-block",
              margin: 10,
              marginTop: 0,
              marginRight: 0,
            }}
          >
            {modifier?.CurrencyRate &&
              modifier?.CurrencyRate > 1 &&
              "Beállított árfolyam:"}
          </div>
          <div
            style={{
              display: "inline-block",
              margin: 10,
              marginTop: 0,
            }}
          >
            {modifier?.CurrencyRate &&
              modifier?.CurrencyRate > 1 &&
              modifier?.CurrencyRate}
          </div>
          <div
            style={{
              fontSize: "22px",
              display: "inline-block",
              margin: 10,
              marginTop: 0,
              marginLeft: "50px",
            }}
          >
            Összesen
          </div>
          <div
            style={{
              fontSize: "22px",
              fontWeight: 700,
              display: "inline-block",
              maxWidth: "20vw",
            }}
          >
            {getModifierTotal(modifier)}
          </div>
        </div>
        <div className={styles.alignRight} style={{ margin: "10px" }}>
          <div
            style={{
              display: "inline-block",
              margin: 10,
              marginTop: 0,
              marginRight: 0,
            }}
          >
            Nyelv:
          </div>
          <div
            style={{
              display: "inline-block",
              margin: 10,
              marginTop: 0,
            }}
          >
            {`Magyar / ${modifier?.Language}`}
          </div>
          <div
            style={{
              fontSize: "22px",
              display: "inline-block",
              margin: 10,
              marginTop: 0,
              marginLeft: "50px",
            }}
          >
            Befizetve
          </div>
          <div
            style={{
              fontSize: "22px",
              fontWeight: 700,
              display: "inline-block",
              maxWidth: "20vw",
            }}
          >
            {getModifierTotal(modifier, true)}
          </div>
        </div>
        <div
          style={{
            position: "relative",

            display: "flex",
          }}
          onClick={handleFlip}
        >
          <div
            style={{
              position: "absolute",
              bottom: "20px",
              left: "20px",
              display: "flex",
            }}
          >
            <span className={styles.comments}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            </span>
            <span
              style={{
                marginLeft: "5px",
                marginTop: "5px",
                cursor: "pointer",
              }}
            >
              Megjegyzések
            </span>
          </div>
        </div>
      </Fragment>

      <div>
        <Comments handleFlip={handleFlip} />
      </div>
    </CardFlip>
  );
};

export default ModifierForm;
