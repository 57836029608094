import { SetStateAction } from "react";
import {
  CurrencyDto,
  CustomerDto,
  MasterDataService,
  ModifierInvoiceDetailDto,
  OpenAPI,
  PaymentMethodDto,
  ProductDto,
  QuoteDetailDto,
  UserSettingsDto,
  UserSettingsService,
} from "../KulcsUzletApi";
import {
  CountryDictionary,
  CurrencyDictionary,
  CustomerCategoryDictionary,
  LanguageDictionary,
  PaymentMethodDictionary,
  TransportModeDictionary,
  VatDictionary,
} from "../models/Dictionaries";
import { Filter, FiltersObject } from "../models/FilterObj";
import { COLOR, LIST_TYPE } from "./Enums";
import { acquireToken } from "./auth/AuthActions";
import { defaultFilterObj, defaultNotDeletedFilter } from "./GlobalConsts";
import _ from "lodash";

export const formatDate = (date: Date | undefined): string => {
  if (date) {
    const formattedDate = new Date(date);

    const year = formattedDate.getFullYear();
    const month = ("0" + (formattedDate.getMonth() + 1)).slice(-2);
    const day = ("0" + formattedDate.getDate()).slice(-2);
    const hours = ("0" + formattedDate.getHours()).slice(-2);
    const minutes = ("0" + formattedDate.getMinutes()).slice(-2);

    return `${year}.${month}.${day}. ${hours}:${minutes}`;
  }
  return "N/A";
};

export const needToRoundTo5 = (
  paymentMethod: PaymentMethodDto | undefined,
  currency: CurrencyDto | undefined
) => {
  return paymentMethod?.IsRoundTo5 === 1 && currency?.Sign === "Ft";
};

export const getvat = (
  vats: VatDictionary | null,
  vat: number | null | undefined
) => (vats && vat ? vats[vat].Rate : undefined);

export const formatNumberWithQuantityUnit = (
  number: number | undefined | null,
  currency: CurrencyDto | undefined,
  decimals: number | undefined | null,
  round?: boolean
) => {
  if (number === undefined || number === null) return "";

  let roundedNumber = getRoundedNumber(decimals, number);
  if (round) {
    roundedNumber = Math.round(number / 5) * 5;
  }

  const formattedNumber = roundedNumber
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");

  const lastSpaceIndex = formattedNumber.lastIndexOf(" ");
  let trimmedNumber = formattedNumber;
  if (decimals && decimals > 3) {
    trimmedNumber =
      lastSpaceIndex !== -1
        ? formattedNumber.substring(0, lastSpaceIndex) +
          formattedNumber.substring(lastSpaceIndex + 1)
        : formattedNumber;
  }
  return currency?.IsPost
    ? `${trimmedNumber} ${currency?.Sign}`
    : `${currency?.Sign} ${trimmedNumber}`;
};

export const roundToTwoDecimals = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const calculatePercentage = (
  number: number | undefined | null,
  percentage: number | undefined
): number => {
  return number && percentage ? (number * percentage) / 100 : 0;
};

export const getCategoryColor = (categoryId: number) => {
  switch (categoryId) {
    case 0:
      return COLOR.BIZ_BLUE;
    case 1:
      return COLOR.BIZ_PURPLE;
    case 2:
      return COLOR.BIZ_RED;
    case 3:
      return COLOR.BIZ_DARK_ORANGE;
    case 4:
      return COLOR.BIZ_ORANGE;
    case 5:
      return COLOR.BIZ_GREEN;
    case 6:
      return COLOR.BIZ_LIGHT_GREEN;
    case 7:
    default:
      return COLOR.BIZ_GREY;
  }
};

export function getStockBalance(
  product: ProductDto | null | undefined,
  voucherDetails:
    | QuoteDetailDto[]
    | ModifierInvoiceDetailDto[]
    | null
    | undefined
) {
  if (!voucherDetails || !product || !product.StockBalance) return 0;
  let detailStockBalance = 0;
  voucherDetails.forEach((detail) => {
    if (((detail as any)?.ProductId ?? (detail as any).Product) === product.Id)
      detailStockBalance += detail.Quantity ?? 0;
  });
  return product.StockBalance - detailStockBalance;
}

export const getPaymentDate = (paymentMethod: PaymentMethodDto | undefined) => {
  const currentDate = new Date();
  let paymentDate = new Date();
  if (paymentMethod && (paymentMethod.DelayDays ?? 0) > 0)
    paymentDate.setDate(currentDate.getDate() + (paymentMethod.DelayDays ?? 0));
  return paymentDate;
};

export const getOptions = async (
  filter?: FiltersObject,
  bisnodeSearch?: string
) => {
  const dbguid = localStorage.getItem("dbGuid");
  let filterObj = JSON.stringify(filter);
  if (filter !== null) {
    for (let i = 0; i < filterObj.length; i++) {
      let charCode = filterObj.charCodeAt(i);
      if (charCode > 160) {
        let x = filterObj.charCodeAt(i).toString(16);
        x = x.padStart(4, "0");
        let y = `\\u${x}`;

        filterObj = filterObj.slice(0, i) + y + filterObj.slice(i + 1);
        i += 5;
      }
    }
  }
  const token = await acquireToken();

  return {
    Authorization: token ?? "",
    "X-Application-Id": "pbyAheWV4MjTHcudjFOl1a1dBUsFxHUO",
    filter: filterObj,
    "X-User-Context": dbguid ?? "",
    "Content-Type": "application/json",
    data: bisnodeSearch ?? "",
  };
};

export const sortInvoiceDetails = (
  list: ModifierInvoiceDetailDto[] | null | undefined
) => {
  if (!list || list.length === 1) return list;

  const sortedDetailList = list.sort((a, b) => {
    if (a.Quantity && b.Quantity) {
      if (a.Quantity < 0 && b.Quantity >= 0) return -1;
      if (a.Quantity >= 0 && b.Quantity < 0) return 1;
    }
    return 0;
  });

  return sortedDetailList;
};

export const trimLongString = (
  string: string | null | undefined,
  length: number
) =>
  string && string?.length > length
    ? string.substring(0, length - 3) + "..."
    : string;

export const base64toBlob = (data: string) => {
  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) out[length] = bytes.charCodeAt(length);

  return new Blob([out], { type: "application/pdf" });
};

export const getSearchPhraseFilterDto = (fieldname: string, value: any) => ({
  FieldName: fieldname,
  FilterValue: value,
  OperatorType: 11,
  FieldType: 0,
});

export const getCategoryDto = (value: any) => ({
  FieldName: "Category1",
  FilterValue: value,
  OperatorType: 0,
  FieldType: 0,
});

export const getCompanyName = async (
  setCompanyName: React.Dispatch<React.SetStateAction<string>>
) => {
  await MasterDataService.getMasterdataCompanyprofile()
    .then((res) => setCompanyName(res.CompanyName ?? ""))
    .catch(() => {});
};

export const arrayToDictionary = (array: any[]) =>
  array?.reduce((acc, item) => {
    acc[item.Id] = item;
    return acc;
  }, {});

export const setKulcsUzletHeaders = async (
  Filters?: FiltersObject,
  bisnodeSearch?: string
) => {
  const headers = await getOptions(
    Filters ?? _.cloneDeep(defaultFilterObj),
    bisnodeSearch
  );
  OpenAPI.HEADERS = headers;
};

export const getProducts = async (
  filtersObj: FiltersObject,
  setProducts: any,
  setPageCount: React.Dispatch<React.SetStateAction<number>>
) => {
  await setKulcsUzletHeaders(filtersObj);
  await MasterDataService.getMasterdataProduct()
    .then(async (res) => {
      setProducts(arrayToDictionary(res));
      setPageCount(
        Math.ceil((res[0]?.TotalCount ?? 0) / (filtersObj.Take ?? 10))
      );
    })
    .catch((e) => console.log(e));
};

export const getCustomers = async (
  filtersObj: FiltersObject,
  setCustomers: React.Dispatch<React.SetStateAction<CustomerDto[]>>,
  setPageCount: React.Dispatch<React.SetStateAction<number>>
) => {
  await setKulcsUzletHeaders(filtersObj);
  await MasterDataService.getMasterdataCustomer()
    .then(async (res) => {
      setCustomers(arrayToDictionary(res));
      setPageCount(
        Math.ceil((res[0]?.TotalCount ?? 0) / (filtersObj.Take ?? 10)) ?? 0
      );
    })
    .catch((e) => console.log(e));
};

export const updateFiltersObj = (
  category: any,
  phrase: any,
  setFiltersObj: React.Dispatch<React.SetStateAction<FiltersObject>>,
  type: LIST_TYPE,
  phraseChanged?: boolean
) => {
  let nonEmptyFilters = [] as Filter[][];
  if (type === LIST_TYPE.PRODUCT) {
    let namefilter: Filter[] = [] as Filter[];
    let codefilter: Filter[] = [] as Filter[];
    if (phrase && phrase !== "") {
      namefilter.push(getSearchPhraseFilterDto("Name", phrase));
      codefilter.push(getSearchPhraseFilterDto("Code", phrase));
    }
    if (category) {
      namefilter.push(getCategoryDto(category));
      codefilter.push(getCategoryDto(category));
    }
    if (namefilter.length > 0) {
      namefilter.push(defaultNotDeletedFilter);
      codefilter.push(defaultNotDeletedFilter);
    } else {
      namefilter.push(defaultNotDeletedFilter);
    }
    nonEmptyFilters = [namefilter, codefilter].filter(
      (filterArray) => filterArray.length > 0
    );
  } else {
    let namefilter: Filter[] = [] as Filter[];
    let codefilter: Filter[] = [] as Filter[];
    let taxfilter: Filter[] = [] as Filter[];
    if (phrase && phrase !== "") {
      namefilter.push(getSearchPhraseFilterDto("Name", phrase));
      codefilter.push(getSearchPhraseFilterDto("Code", phrase));
      taxfilter.push(getSearchPhraseFilterDto("TaxNumber", phrase));
    }

    if (category) {
      namefilter.push(getCategoryDto(category));
      codefilter.push(getCategoryDto(category));
      taxfilter.push(getCategoryDto(category));
    }
    if (namefilter.length > 0) {
      namefilter.push(defaultNotDeletedFilter);
      codefilter.push(defaultNotDeletedFilter);
      taxfilter.push(defaultNotDeletedFilter);
    } else {
      namefilter.push(defaultNotDeletedFilter);
    }
    nonEmptyFilters = [namefilter, codefilter, taxfilter].filter(
      (filterArray) => filterArray.length > 0
    );
  }

  setFiltersObj((prev) => ({
    ...prev,
    Skip: phraseChanged ? 0 : prev.Skip,
    Filters: nonEmptyFilters,
  }));
};

export const changeFiltersObjPagination = (
  skip: "prev" | "next",
  setFiltersObj: React.Dispatch<React.SetStateAction<FiltersObject>>,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
  currentPage: number
) => {
  switch (skip) {
    case "prev":
      setFiltersObj((prev) => ({ ...prev, Skip: prev.Skip - prev.Take }));
      setCurrentPage(Number(currentPage) - 1);
      break;
    case "next":
      setFiltersObj((prev) => ({ ...prev, Skip: prev.Skip + prev.Take }));
      setCurrentPage(Number(currentPage) + 1);
      break;
    default:
      break;
  }
};

export const getMasterData = async (
  setQuantityUnits: any,
  setVats: any,
  setProductCategories: any,
  setProjects: any,
  setTransportModes?: React.Dispatch<
    SetStateAction<TransportModeDictionary | null>
  >,
  setCustomerCategories?: React.Dispatch<
    SetStateAction<CustomerCategoryDictionary | null>
  >,
  setCurrencies?: React.Dispatch<SetStateAction<CurrencyDictionary | null>>,
  setPaymentMethods?: React.Dispatch<
    SetStateAction<PaymentMethodDictionary | null>
  >,
  setCountries?: React.Dispatch<SetStateAction<CountryDictionary | null>>,
  setLanguages?: React.Dispatch<SetStateAction<LanguageDictionary | null>>,
  setUserSettings?: React.Dispatch<SetStateAction<UserSettingsDto | null>>
) => {
  await MasterDataService.getMasterdataQuantityunit()
    .then((res) =>
      setQuantityUnits(arrayToDictionary(res?.filter((i) => !i?.Deleted)))
    )
    .catch((e) => console.log(e));

  await MasterDataService.getMasterdataVat()
    .then((res) => setVats(arrayToDictionary(res?.filter((i) => !i?.Deleted))))
    .catch((e) => console.log(e));

  await MasterDataService.getMasterdataProductcategory()
    .then((res) => setProductCategories(arrayToDictionary(res)))
    .catch((e) => console.log(e));

  await MasterDataService.getMasterdataProject()
    .then((res) =>
      setProjects(arrayToDictionary(res?.filter((i) => !i?.Deleted)))
    )
    .catch((e) => console.log(e));

  if (setTransportModes) {
    await MasterDataService.getMasterdataTransportMode()
      .then((res) =>
        setTransportModes(arrayToDictionary(res?.filter((i) => !i?.Deleted)))
      )
      .catch((e) => console.log(e));
  }
  if (setCustomerCategories) {
    await MasterDataService.getMasterdataClientcategory()
      .then((res) => setCustomerCategories(arrayToDictionary(res)))
      .catch((e) => console.log(e));
  }
  if (setCurrencies) {
    await MasterDataService.getMasterdataCurrency()
      .then((res) => setCurrencies(arrayToDictionary(res)))
      .catch((e) => console.log(e));
  }
  if (setPaymentMethods) {
    await MasterDataService.getMasterdataPaymentmethod()
      .then((res) =>
        setPaymentMethods(arrayToDictionary(res?.filter((i) => !i?.Deleted)))
      )
      .catch((e) => console.log(e));
  }
  if (setCountries) {
    await MasterDataService.getMasterdataCountry()
      .then((res) => setCountries(arrayToDictionary(res)))
      .catch((e) => console.log(e));
  }
  if (setLanguages) {
    await MasterDataService.getMasterdataInvoicelanguage()
      .then((res) => setLanguages(res))
      .catch((e) => console.log(e));
  }
  if (setUserSettings) {
    await UserSettingsService.getUsersettings()
      .then((res) => setUserSettings(res))
      .catch((e) => console.log(e));
  }
};

export const getCustomerAddress = (customer: CustomerDto | undefined) => {
  return `${customer?.CentralZip ?? ""} ${customer?.CentralCity ?? ""} ${
    customer?.CentralStreet ?? ""
  } ${customer?.CentralHouseNumber ?? ""}`;
};

export function getRoundedNumber(
  digits: number | undefined | null,
  number: number | null | undefined
) {
  if (number) {
    if (digits || digits === 0) {
      const power = Math.pow(10, digits);
      const roundedNetUnitPrice = Math.round(number * power) / power;
      return roundedNetUnitPrice;
    } else return number;
  } else return 0;
}
