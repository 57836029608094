import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStylesHeader = makeStyles({
  container: {
    width: "100%",
    paddingLeft: "0%",
    height: "70px",
    paddingTop: "10px",
    ...shorthands.borderTop("4px", "solid", "#3f76bb"),
  },
  logo: {
    width: "223px",
    height: "50px",
    marginLeft: "2.8%",
    cursor: "pointer",
  },
  title: {
    textAlign: "center",
    marginTop: "-40px",
    fontSize: "21px",
    fontFamily: "'Oxygen', sans-serif",
    fontWeight: 700,

    color: "#333333",
  },
});
