import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStylesDialogEditCustomerStyle = makeStyles({
  table: { marginTop: "-10px", marginBottom: "15px" },
  first: {
    marginRight: "3%",
    float: "left",
    width: "48.5%",
  },
  second: {
    float: "left",
    width: "48.5%",
  },
  line: {
    height: "32px",
    float: "left",
    width: "100%",
    marginBottom: "20px",
  },
  mobileline: {
    height: "32px",
    float: "left",
    width: "100%",
    marginBottom: "20px",
    // marginTop: "5px",
  },
  fpart: {
    width: "30%",
    float: "left",
    height: "32px",
    fontSize: "14px",
    marginTop: "5px",
  },
  mobilefpart: {
    width: "100%",
    float: "left",
    fontSize: "13px",
    color: "#939393",
  },
  fpartText: { textAlign: "right", marginRight: "10px" },
  spart: { width: "70%", float: "left", height: "32px" },
  mobilespart: {
    width: "100%",
    float: "left",
  },
  fcolumn: { fontWeight: "bolder", fontSize: "16px", width: "320px" },
  scolumn: { width: "320px" },
  fspan: { display: "inline-block", width: "120px" },
  sspan: { display: "inline-block", width: "90px", textAlign: "center" },
  tabs: {
    width: "100%",
    height: "52px",
    ...shorthands.borderBottom("4px", "solid", "#3f76bb"),
  },
  wrapper: {
    marginRight: "35px",
    marginTop: "4px",
    display: "inline-block",
    width: "52px",
    height: "48px",
    backgroundColor: "#939393",
    cursor: "pointer",
    WebkitBorderRadius: "10px 10px 0px 0px",
    MozBorderRadius: "10px 10px 0px 0px",
    MsBorderRadius: "10px 10px 0px 0px",
    ...shorthands.borderRadius("10px", "10px", "0px", "0px"),
    WebkitTransition: "background-color 500ms ease-out 200ms",
    MozTransition: "background-color 500ms ease-out 200ms",
    OTransition: "background-color 500ms ease-out 200ms",
    ...shorthands.transition("background-color", "500ms", "ease-out", "200ms"),
  },
  mobilewrapper: {
    marginTop: "4px",
    display: "inline-block",
    width: "25%",
    height: "48px",
    backgroundColor: "#939393",
    cursor: "pointer",
    WebkitBorderRadius: "10px 10px 0px 0px",
    MozBorderRadius: "10px 10px 0px 0px",
    MsBorderRadius: "10px 10px 0px 0px",
    ...shorthands.borderRadius("10px", "10px", "0px", "0px"),
    WebkitTransition: "background-color 500ms ease-out 200ms",
    MozTransition: "background-color 500ms ease-out 200ms",
    OTransition: "background-color 500ms ease-out 200ms",
    ...shorthands.transition("background-color", "500ms", "ease-out", "200ms"),
  },
  requiredFieldHelp: { color: "black", lineHeight: "0px" },
  mobilerequiredFieldHelp: {
    color: "black",
    lineHeight: "0px",
    fontSize: "12px",
  },
  tabicon: {
    ...shorthands.margin("8px", "auto"),
    textAlign: "center",
    width: "32px",
    height: "32px",
  },
  mobiletabicon: {
    ...shorthands.margin("8px", "auto"),
    textAlign: "center",
    width: "32px",
    height: "32px",
  },
  isPrivate: {
    paddingTop: "10px",
    float: "right",
  },
  isPrivateText: { float: "left", marginRight: "10px", lineHeight: "26px" },
  mobileisPrivateText: {
    float: "left",
    marginRight: "10px",
    lineHeight: "26px",
    fontSize: "13px",
    color: "#939393",
  },
  isPrivateSwitch: { float: "left", marginTop: "-5px" },
  tabChanged: {
    opacity: 0,
    ...shorthands.transition("opacity", "1s"),
  },
  hide: {
    opacity: 0,
    pointerEvents: "none",
    ...shorthands.transition("opacity", "1s", "ease-in-out"),
    display: "none",
  },
  tab: {
    marginTop: "30px",
    height: "275px",
    opacity: 1,
    ...shorthands.transition("opacity", "1s", "ease-in-out"),
  },
});
