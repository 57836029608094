import {
  DialogActions,
  DialogTrigger,
  Button,
} from "@fluentui/react-components";
import { DialogActionsProps } from "../../interfaces/DialogActionsProps";
import { COLOR } from "../../utils/Enums";
import { ClipLoader } from "react-spinners";

const DialogActionsComponent: React.FC<DialogActionsProps> = ({
  closeBtnText,
  saveBtnText,
  saveAction,
  isSaveLoading,
}) => {
  return (
    <DialogActions fluid>
      {saveAction && (
        <Button
          appearance="primary"
          style={{
            backgroundColor: COLOR.BIZ_DARK_ORANGE,
            fontFamily: "Oxygen",
          }}
          onClick={saveAction}
        >
          {isSaveLoading ? <ClipLoader size={16} color="white" /> : saveBtnText}
        </Button>
      )}
      {closeBtnText && (
        <DialogTrigger disableButtonEnhancement>
          <Button style={{ fontFamily: "Oxygen" }} appearance="secondary">
            {closeBtnText ?? "Ok"}
          </Button>
        </DialogTrigger>
      )}
    </DialogActions>
  );
};

export default DialogActionsComponent;
