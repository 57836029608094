import { useState, useContext, useEffect, Fragment } from "react";
import styles from "./CompanySearcharbleSelect.module.scss";
import { BisnodeDataService, CustomerDto } from "../../KulcsUzletApi";
import {
  BISNODEPARTNER_STATUS,
  BisnodePartner,
} from "../../models/BisnodePartner";
import { FilterContext } from "../../utils/GlobalContexts";
import { setKulcsUzletHeaders } from "../../utils/HelperFunctions";

type Props = {
  parentId: string;
  setCustomerObj?: React.Dispatch<
    React.SetStateAction<CustomerDto | undefined>
  >;
  value: any;
  isFocusedInput: boolean;
};

const CompanySearcharbleSelect = ({
  parentId,
  setCustomerObj,
  value,
  isFocusedInput,
}: Props) => {
  const [options, setOptions] = useState([] as BisnodePartner[]);
  const [isFocused, setIsFocused] = useState(false);
  const [shouldClose, setShouldClose] = useState(false);
  const [parentWidth, setParentWidth] = useState(0);
  const { filtersObj } = useContext(FilterContext);

  useEffect(() => {
    const parentOffsetWidth = document.getElementById(parentId)?.offsetWidth;
    let width = 0;
    if (parentOffsetWidth) width = parentOffsetWidth - 4;
    setParentWidth(width);
  }, []);

  useEffect(() => {
    if (!isFocusedInput) return;
    setIsFocused(true);
    if (value?.length > 2) {
      searchBisnode();
    }
  }, [value]);

  // useEffect(() => {
  //   if (!isFocusedInput) setShouldClose(true);
  // }, [isFocusedInput]);

  const searchBisnode = async () => {
    const value2 = encodeURIComponent(value);
    await setKulcsUzletHeaders(filtersObj, value2);

    await BisnodeDataService.getBisnodeSearch()
      .then((res) => {
        if (res !== null || res[0] !== null) {
          let partners = res as BisnodePartner[];
          let filtered =
            partners.filter(
              (partner) => partner.Status !== BISNODEPARTNER_STATUS.CEASED
            ) ?? [];
          setOptions(filtered);
        }
      })
      .catch((e) => console.log(e));
  };

  const isShown = () => isFocused && value?.length > 0 && options?.length > 0;

  function format(mask: string, number: string | number) {
    var s = "" + number,
      r = "";
    for (var im = 0, is = 0; im < mask.length && is < s.length; im++) {
      r += mask.charAt(im) === "X" ? s.charAt(is++) : mask.charAt(im);
    }
    return r;
  }

  const selectPartner = async (partner: BisnodePartner) => {
    if (setCustomerObj)
      await setCustomerObj((prev: CustomerDto | undefined) => ({
        ...prev,
        Name: partner.ShortName ?? partner.Name ?? "",
        CentralStreet: partner.Address ?? "",
        CentralCity: partner.City ?? "",
        CentralZip: partner.Zip ?? "",
        TaxNumber: format("XXXXXXXX-X-XX", partner.TaxNbr) ?? "",
      }));
    await setShouldClose(true);
  };

  useEffect(() => {
    if (shouldClose) {
      setOptions([]);
      setIsFocused(false);
      setShouldClose(false);
      setKulcsUzletHeaders(filtersObj, "");
    }
  }, [shouldClose]);

  return (
    <Fragment>
      {isShown() && (
        <ul
          className={styles["option-container"]}
          style={{ width: parentWidth }}
        >
          {options.map((bisnodePartner, index) => (
            <li
              className={styles.item}
              key={index}
              onClick={() => selectPartner(bisnodePartner)}
            >
              <div>
                <b>{bisnodePartner?.ShortName ?? ""}</b>
              </div>
              <div>{bisnodePartner?.Name ?? ""}</div>
              <span>
                <b>Adószám: </b>
                {bisnodePartner?.TaxNbr ?? ""} <b>Státusz:</b>{" "}
                {bisnodePartner?.Status ?? ""}{" "}
              </span>
              <div>
                <b>Cím: </b>
                {bisnodePartner?.Address ?? ""}
              </div>
            </li>
          ))}
        </ul>
      )}
    </Fragment>
  );
};

export default CompanySearcharbleSelect;
