/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiKeysDto } from '../models/ApiKeysDto';
import type { ResultDto } from '../models/ResultDto';
import type { UserSettingsDto } from '../models/UserSettingsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserSettingsService {

    /**
     * @returns UserSettingsDto Success
     * @throws ApiError
     */
    public static getUsersettings(): CancelablePromise<UserSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/usersettings',
        });
    }

    /**
     * @param requestBody 
     * @returns UserSettingsDto Success
     * @throws ApiError
     */
    public static postUsersettings(
requestBody?: UserSettingsDto,
): CancelablePromise<UserSettingsDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/usersettings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserSettingsDto Success
     * @throws ApiError
     */
    public static getUsersettingsGlobalsettings(): CancelablePromise<UserSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/usersettings/globalsettings',
        });
    }

    /**
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getUsersettingsActivateapi(): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/usersettings/activateapi',
        });
    }

    /**
     * @returns ApiKeysDto Success
     * @throws ApiError
     */
    public static getUsersettingsGetapikeys(): CancelablePromise<ApiKeysDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/usersettings/getapikeys',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postUsersettingsCheckcashbookauth(
requestBody?: UserSettingsDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/usersettings/checkcashbookauth',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
