import { useState } from "react";
import "./App.scss";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import {
  DeviceContext,
  DeviceProps,
  FilterContext,
  FilterProps,
  MasterDataContext,
  MasterDataProps,
  ModifierContext,
  ModifierProps,
  QuoteContext,
  QuoteProps,
  RefreshContext,
  RefreshProps,
} from "./utils/GlobalContexts";
import {
  CountryDictionary,
  CurrencyDictionary,
  CustomerCategoryDictionary,
  CustomerDictionary,
  PaymentMethodDictionary,
  ProductCategoryDictionary,
  ProductDictionary,
  ProjectDictionary,
  QuantityUnitDictionary,
  TransportModeDictionary,
  VatDictionary,
} from "./models/Dictionaries";
import {
  LanguageDto,
  ModifierInvoiceDto,
  OpenAPI,
  QuoteDto,
  UserSettingsDto,
} from "./KulcsUzletApi";
import { acquireToken } from "./utils/auth/AuthActions";
import { FiltersObject } from "./models/FilterObj";

export function Providers({ children }: any) {
  OpenAPI.TOKEN = async () => (await acquireToken()) as any;
  OpenAPI.BASE = process.env.REACT_APP_API_URL as string;

  const [refresh, setRefresh] = useState(false);
  const [products, setProducts] = useState<null | ProductDictionary>(null);
  const [transportModes, setTransportModes] =
    useState<null | TransportModeDictionary>(null);
  const [projects, setProjects] = useState<null | ProjectDictionary>(null);
  const [customers, setCustomers] = useState<null | CustomerDictionary>(null);
  const [currencies, setCurrencies] = useState<null | CurrencyDictionary>(null);
  const [countries, setCountries] = useState<null | CountryDictionary>(null);
  const [languages, setLanguages] = useState<null | LanguageDto[]>(null);
  const [filtersObj, setFiltersObj] = useState({} as FiltersObject);
  const [userSettings, setUserSettings] = useState<null | UserSettingsDto[]>(
    null
  );
  const [paymentMethods, setPaymentMethods] =
    useState<null | PaymentMethodDictionary>(null);
  const [vats, setVats] = useState<null | VatDictionary>(null);
  const [quantityUnits, setQuantityUnits] =
    useState<null | QuantityUnitDictionary>(null);
  const [productCategories, setProductCategories] =
    useState<null | ProductCategoryDictionary>(null);
  const [customerCategories, setCustomerCategories] =
    useState<null | CustomerCategoryDictionary>(null);
  const [modifier, setModifier] = useState<null | ModifierInvoiceDto>(null);
  const [quote, setQuote] = useState<null | QuoteDto>(null);
  const [isMobile, setIsMobile] = useState(false);

  return (
    <FluentProvider theme={webLightTheme}>
      <div className="App">
        <DeviceContext.Provider
          value={
            {
              isMobile,
              setIsMobile,
            } as DeviceProps
          }
        >
          <FilterContext.Provider
            value={{ filtersObj, setFiltersObj } as FilterProps}
          >
            <RefreshContext.Provider
              value={{ refresh, setRefresh } as RefreshProps}
            >
              <MasterDataContext.Provider
                value={
                  {
                    products,
                    setProducts,
                    transportModes,
                    setTransportModes,
                    projects,
                    setProjects,
                    vats,
                    setVats,
                    productCategories,
                    setProductCategories,
                    customerCategories,
                    setCustomerCategories,
                    quantityUnits,
                    setQuantityUnits,
                    customers,
                    setCustomers,
                    currencies,
                    setCurrencies,
                    paymentMethods,
                    setPaymentMethods,
                    countries,
                    setCountries,
                    languages,
                    setLanguages,
                    userSettings,
                    setUserSettings,
                  } as MasterDataProps
                }
              >
                <ModifierContext.Provider
                  value={
                    {
                      modifier,
                      setModifier,
                    } as ModifierProps
                  }
                >
                  <QuoteContext.Provider
                    value={{ quote, setQuote } as QuoteProps}
                  >
                    {children}
                  </QuoteContext.Provider>
                </ModifierContext.Provider>
              </MasterDataContext.Provider>
            </RefreshContext.Provider>
          </FilterContext.Provider>
        </DeviceContext.Provider>
      </div>
    </FluentProvider>
  );
}

export default Providers;
