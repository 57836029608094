import * as React from "react";
import DialogSkeleton from "./DialogSkeleton";
import { DialogProps } from "../../interfaces/DialogConfirmProps";

const DialogConfirm: React.FC<DialogProps> = ({
  title,
  message,
  open,
  setOpen,
  handleAction,
  okBtnText,
  closeBtnText,
}) => {
  return (
    <DialogSkeleton
      open={open}
      setOpen={setOpen}
      title={title}
      saveAction={handleAction}
      saveBtnText={okBtnText}
      closeBtnText={closeBtnText}
    >
      <div dangerouslySetInnerHTML={{ __html: message }}></div>
    </DialogSkeleton>
  );
};

export default DialogConfirm;
