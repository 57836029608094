import { useStylesHeader } from "../../styles/HeaderStyle";
import { HeaderProps } from "../../interfaces/HeaderProps";
import { Fragment } from "react";
import logo from "../../assets/header/bizxpert_logo_dashboard.png";

const Header: React.FC<HeaderProps> = ({ companyName }) => {
  const styles = useStylesHeader();
  const goBack = () =>
    window.location.replace(
      process.env.REACT_APP_KULCSUZLET_BASE_URL as string
    );

  return (
    <Fragment>
      <div className={styles.container}>
        <div className={styles.logo} onClick={goBack}>
          <img src={logo} alt="" height={"45px"} />
        </div>
        <div className={styles.title}>{companyName}</div>
      </div>
    </Fragment>
  );
};

export default Header;
