import { Fragment, useContext, useEffect, useState } from "react";
import PageHeader from "../components/page-header/PageHeader";
import { useStylesMainPage } from "../styles/MainPageStyles";
import ModifierForm from "../components/forms/ModifierForm";
import ListContainer from "../components/lists/ListContainer";
import {
  DeviceContext,
  MasterDataContext,
  ModifierContext,
} from "../utils/GlobalContexts";
import _ from "lodash";
import { ModifierInvoiceService } from "../KulcsUzletApi";
import {
  getCompanyName,
  getMasterData,
  getPaymentDate,
  sortInvoiceDetails,
} from "../utils/HelperFunctions";
import DialogConfirm from "../components/dialog/DialogConfirm";
import { TAB_TYPE, LIST_TYPE, LOCAL_STORAGE_KEY } from "../utils/Enums";
import Header from "../components/header/Header";
import MobileTabs from "../components/mobile-tabs/MobileTabs";
import ModifierFormMobile from "../components/forms/ModifierFormMobile";
import MobileBottomMenu from "../components/bottom-menu/MobileBottomMenu";
import { useParams } from "react-router-dom";

export default function ModifierInvoicePage() {
  const styles = useStylesMainPage();
  const { setVats, setQuantityUnits, setProductCategories, setProjects } =
    useContext(MasterDataContext);
  const { setModifier } = useContext(ModifierContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState<string | null>(null);
  const [companyName, setCompanyName] = useState("");
  const [activeTab, setActiveTab] = useState(TAB_TYPE.PRODUCTS);
  const [originalItemIds, setOriginalItemIds] = useState([] as number[]);

  const { isMobile } = useContext(DeviceContext);

  useEffect(() => {
    if (companyName === "") {
      getCompanyName(setCompanyName);
    }
  }, [companyName]);

  useEffect(() => {
    getData();
    getMasterData(setQuantityUnits, setVats, setProductCategories, setProjects);
  }, []);

  const getData = async () => {
    let originalItems = [] as number[];
    let modifierId = localStorage.getItem("id");
    if (modifierId) {
      await ModifierInvoiceService.getModifierInvoice(Number(modifierId))
        .then((res) => {
          if (res && res.DetailList) {
            res.DetailList.forEach((detail) => {
              let negativeDetail = _.cloneDeep(detail);
              if (detail.Quantity) {
                negativeDetail.IsNewItem = false;
                negativeDetail.Quantity = detail.Quantity * -1;
                negativeDetail.Id = Math.floor(Math.random() * 10000000);
                originalItems.push(negativeDetail.Id ?? -1);
                res.DetailList?.push(negativeDetail);
              }
            });
            setOriginalItemIds(originalItems);
            res.DetailList = sortInvoiceDetails(res.DetailList);
            res.PaymentDate = getPaymentDate(res.PaymentMethod);
            res.FulfillmentDate = res.FulfillmentDate
              ? new Date(res.FulfillmentDate)
              : new Date();
            setModifier(res);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const openModal = (text?: string) => {
    if (text) setDialogText(text);
    setOpenDialog(true);
  };

  return (
    <Fragment>
      {isMobile ? (
        <Fragment>
          <div className={styles.containerMobile}>
            <div className={styles.invoiceContainerMobile}>
              <MobileTabs activeTab={activeTab} setActiveTab={setActiveTab} />
              {activeTab === TAB_TYPE.PRODUCTS && (
                <ListContainer type={LIST_TYPE.PRODUCT} />
              )}
              {activeTab === TAB_TYPE.INVOICE && (
                <ModifierFormMobile
                  openModal={openModal}
                  originalItemIds={originalItemIds}
                />
              )}
              <MobileBottomMenu
                openResultModal={openModal}
                activeTab={activeTab}
              />
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <Header companyName={companyName} />
          <div className={styles.container}>
            <PageHeader title="Helyesbítő számla" />
            <div className={styles.innerContainer} style={{ marginBottom: 0 }}>
              <div className={styles.containerLeft}>
                <ListContainer type={LIST_TYPE.PRODUCT} />
              </div>
              <div className={styles.containerRight}>
                <ModifierForm
                  openModal={openModal}
                  originalItemIds={originalItemIds}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
      <DialogConfirm
        okBtnText="Igen"
        closeBtnText="Nem"
        message={dialogText ?? ""}
        title=""
        handleAction={() => console.log()}
        open={openDialog}
        setOpen={setOpenDialog}
      />
    </Fragment>
  );
}
