import * as msal from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_UI_CLIENT_ID as string,
    authority:
      ("https://kulcssoftextidentity.b2clogin.com/kulcssoftextidentity.onmicrosoft.com/" +
        process.env.REACT_APP_B2C_LOGIN_FLOW +
        "/") as string,
    knownAuthorities: [
      ("https://kulcssoftextidentity.b2clogin.com/kulcssoftextidentity.onmicrosoft.com/" +
        process.env.REACT_APP_B2C_LOGIN_FLOW +
        "/oauth2/v2.0/authorize") as string,
    ],
    redirectUri: "/",
    cache: {
      cacheLocation: "localStorage",
    },
  },
};

export const loginRequest = {
  scopes: [
    "openid",
    "offline_access",
    `${process.env.REACT_APP_AUDIENCE}/${process.env.REACT_APP_SCOPE_WRITE}`,
  ],
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export { msalInstance };
