/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatchPrintDto } from '../models/BatchPrintDto';
import type { DocumentToPDFRequestDto } from '../models/DocumentToPDFRequestDto';
import type { DocumentToPDFResultDto } from '../models/DocumentToPDFResultDto';
import type { ExpenseFromInvoiceDto } from '../models/ExpenseFromInvoiceDto';
import type { InvoiceDto } from '../models/InvoiceDto';
import type { MailgunResultDto } from '../models/MailgunResultDto';
import type { PreviewResultDto } from '../models/PreviewResultDto';
import type { QuoteDto } from '../models/QuoteDto';
import type { RecommendationDto } from '../models/RecommendationDto';
import type { ResultDto } from '../models/ResultDto';
import type { SendDigitalProductDto } from '../models/SendDigitalProductDto';
import type { SendDocumentRequestDto } from '../models/SendDocumentRequestDto';
import type { SendStatementsRequestDto } from '../models/SendStatementsRequestDto';
import type { SetSendPrintPDFDateTimeRequestDto } from '../models/SetSendPrintPDFDateTimeRequestDto';
import type { StockMoveDto } from '../models/StockMoveDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentPrintService {

    /**
     * @param requestBody 
     * @returns PreviewResultDto Success
     * @throws ApiError
     */
    public static postDocumentprintInvoicepreview(
requestBody?: InvoiceDto,
): CancelablePromise<PreviewResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documentprint/invoicepreview',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pInvoiceId 
     * @returns PreviewResultDto Success
     * @throws ApiError
     */
    public static getDocumentprintInvoicepreview(
pInvoiceId: number,
): CancelablePromise<PreviewResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/documentprint/invoicepreview/{pInvoiceId}',
            path: {
                'pInvoiceId': pInvoiceId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns BatchPrintDto Success
     * @throws ApiError
     */
    public static postDocumentprintGetbatchedinvoices(
requestBody?: BatchPrintDto,
): CancelablePromise<BatchPrintDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documentprint/getbatchedinvoices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns BatchPrintDto Success
     * @throws ApiError
     */
    public static postDocumentprintBatchPrintPdf(
requestBody?: Array<number>,
): CancelablePromise<BatchPrintDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documentprint/batch/printPdf',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns PreviewResultDto Success
     * @throws ApiError
     */
    public static postDocumentprintQuotepreview(
requestBody?: QuoteDto,
): CancelablePromise<PreviewResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documentprint/quotepreview',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pQuoteId 
     * @returns PreviewResultDto Success
     * @throws ApiError
     */
    public static getDocumentprintQuotepreview(
pQuoteId: number,
): CancelablePromise<PreviewResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/documentprint/quotepreview/{pQuoteId}',
            path: {
                'pQuoteId': pQuoteId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns MailgunResultDto Success
     * @throws ApiError
     */
    public static postDocumentprintSendvoucher(
requestBody?: SendDocumentRequestDto,
): CancelablePromise<MailgunResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documentprint/sendvoucher',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns MailgunResultDto Success
     * @throws ApiError
     */
    public static postDocumentprintSendvoucherbyapi(
requestBody?: SendDocumentRequestDto,
): CancelablePromise<MailgunResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documentprint/sendvoucherbyapi',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static getDocumentprintStatementEmailBody(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/documentprint/statement-email-body',
        });
    }

    /**
     * @param requestBody 
     * @returns MailgunResultDto Success
     * @throws ApiError
     */
    public static postDocumentprintSendstatements(
requestBody?: SendStatementsRequestDto,
): CancelablePromise<MailgunResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documentprint/sendstatements',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postDocumentprintStatementPdf(
requestBody?: Array<number>,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documentprint/statement-pdf',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns DocumentToPDFResultDto Success
     * @throws ApiError
     */
    public static postDocumentprintPdf(
requestBody?: DocumentToPDFRequestDto,
): CancelablePromise<DocumentToPDFResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documentprint/pdf',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postDocumentprintSetsendprintpdfdatetime(
requestBody?: SetSendPrintPDFDateTimeRequestDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documentprint/setsendprintpdfdatetime',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param secret 
     * @returns DocumentToPDFResultDto Success
     * @throws ApiError
     */
    public static getDocumentprintRemoteprint(
secret?: string,
): CancelablePromise<DocumentToPDFResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/documentprint/remoteprint',
            query: {
                'secret': secret,
            },
        });
    }

    /**
     * @param voucherId 
     * @param voucherType 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getDocumentprintRemoteprinturl(
voucherId?: number,
voucherType?: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/documentprint/remoteprinturl',
            query: {
                'voucherId': voucherId,
                'voucherType': voucherType,
            },
        });
    }

    /**
     * @param secret 
     * @returns ExpenseFromInvoiceDto Success
     * @throws ApiError
     */
    public static getDocumentprintInvoicedata(
secret?: string,
): CancelablePromise<ExpenseFromInvoiceDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/documentprint/invoicedata',
            query: {
                'secret': secret,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns MailgunResultDto Success
     * @throws ApiError
     */
    public static postDocumentprintSendrecommendation(
requestBody?: RecommendationDto,
): CancelablePromise<MailgunResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documentprint/sendrecommendation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns PreviewResultDto Success
     * @throws ApiError
     */
    public static postDocumentprintStockmovepreview(
requestBody?: StockMoveDto,
): CancelablePromise<PreviewResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documentprint/stockmovepreview',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pStockMoveId 
     * @returns PreviewResultDto Success
     * @throws ApiError
     */
    public static getDocumentprintStockmovepreview(
pStockMoveId: number,
): CancelablePromise<PreviewResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/documentprint/stockmovepreview/{pStockMoveId}',
            path: {
                'pStockMoveId': pStockMoveId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns MailgunResultDto Success
     * @throws ApiError
     */
    public static postDocumentprintSenddigitalproduct(
requestBody?: SendDigitalProductDto,
): CancelablePromise<MailgunResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documentprint/senddigitalproduct',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
